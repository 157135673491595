<script>
export default {
	name: "BannerComponent",
	components:{},
	data(){
		return{
		}
	},
	props: {
		banners: Array
	},
	methods: {
		routeDiv(link){
			location.href = link;
		}
	},
	mounted() {}
}
</script>
<template>
	<section class="banner">
		<div class="container-xxl">
			<div class="swiper-banner swiper">
				<div class="swiper-wrapper">
					<div
						@click="routeDiv(banner.link)"
						v-for="banner in banners"
						:key="banner.bannerId"
						class="swiper-slide"
						v-bind:style="{ backgroundImage: 'url(' + banner.image + ')' }"
					></div>
				</div>
				<div class="banner-button-next d-none d-md-flex w-auto h-auto translate-middle-y mt-0"></div>
				<div class="banner-button-prev d-none d-md-flex w-auto h-auto translate-middle-y mt-0"></div>
				<div class="swiper-pagination d-md-none position-relative"></div>
			</div>
		</div>
	</section>
</template>
<style scoped>
.swiper-slide {
	display: flex;
	flex-flow: row;
	flex-direction: row;
	justify-content: center;
	overflow: hidden;
}

.swiper-slide > .slide-element {
	position: absolute;
	top: 0;
	left: 0;
	max-width: 100%;
}

.swiper-slide > .slide-element.visible {
	opacity: 1;
	visibility: visible !important;
	z-index: 0;
}

.swiper-slide > .slide-element:not(.visible) {
	z-index: -9;
	opacity: 0;
	visibility: hidden;
}

.banner-button-next, .banner-button-prev {
	position: absolute;
	right: 24px;
	top: 50%;
	z-index: 4;
	width: 48px !important;
	height: 48px !important;
	border-radius: 50%;
	text-align: center;
	line-height: 48px;
	font-family: 'icomoon';
	color: #fff;
	font-size: 1.5rem;
}

.banner-button-prev {
	right: unset;
	left: 24px;
}

.banner-button-next:after {
	content: "\e902";
	width: 100%;
	height: 100%;
}

.banner-button-prev:after {
	content: "\e901";
	width: 100%;
	height: 100%;
}
</style>
<script>
/* eslint-disable */
export default {
    name: "SimpleTableProfile",
    props: {
      tableData: Array,
      eventType: Number
    },
    methods: {
      eventDetail(eventId){
        location.href = "/event-detail/"+eventId
      }
    }
  }
</script>
<template>
	<table class="table events-table mb-0">
		<thead>
		<tr>
			<th class="fw-semibold">Etkinlik</th>
			<th class="fw-semibold">Giriş Ücreti</th>
			<th class="fw-semibold">Başlangıç Tarihi</th>
      <th class="fw-semibold">Video Yükleme Bitiş Tarihi</th>
      <th class="fw-semibold">Bitiş Tarihi</th>
			<th class="fw-semibold">Toplam Ödül</th>
			<th class="fw-semibold">Katılım</th>
			<th class="fw-semibold">Durum</th>
		</tr>
		</thead>
		<tbody>
		<tr v-for="data in tableData" :key="data.eventId">
			<td class="fw-medium">{{data.name}}</td>
			<td class="fw-medium"><span class="badge bg-yellow fw-medium d-inline-block position-relative rounded-0">Ücretsiz</span></td>
			<td class="fw-medium" v-if="data.type== 0 || data.type== 2 || data.type== 4">
        {{data.startingDate}}
			</td>
      <td class="fw-medium" v-if="data.type== 1 || data.type== 3">
        <span v-if="data.type" class="badge bg-slate-blue fw-medium rounded-5">Açık</span>
      </td>
      <td class="fw-medium">{{data.lastVideoUploadDate}}</td>
      <td class="fw-medium">{{data.endingDate}}</td>
			<td class="fw-semibold color-violet">{{data.awardFee.toLocaleString("tr-TR")}} ₺</td>
			<td class="fw-semibold">-</td>
			<td>
        <span v-if="data.type==0" class="badge fw-medium rounded-5 bg-slate-blue bg-green" >Yakında!</span>
        <button v-if="data.type==1 || data.type==2" @click="eventDetail(data.eventId)" type="button" class="btn btn-tomato-sm fw-medium rounded-5 shadow-none p-1">Video Yükle</button>
        <span v-if="data.type==4 || data.type==3" class="badge fw-medium rounded-5 bg-slate-blue bg-red">Tamamlandı</span>
      </td>
		</tr>
		</tbody>
	</table>
</template>
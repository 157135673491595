<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Loading
  },
  computed:{
    getSpinnerStatus() {
      return this.$store.getters.getSpinnerShow;
    }
  },
  data(){
    return{
      isAuth: false,
    }
  },
  methods: {
    checkAuth(){
      if (window.sessionStorage.getItem("Token") && window.sessionStorage.getItem("Token") !== null){
        return this.isAuth = true
      }else{
        return this.isAuth = false
      }
    }
  },
  mounted() {
    this.checkAuth();
  }
}
</script>

<template>
  <Header :isAuth="isAuth"/>
  <router-view />
  <Loading :active="getSpinnerStatus"
           :can-cancel="true"
           loader="dots"
           width="196"
           color="#8A39FF"
           :is-full-page="true"/>
  <Footer/>
</template>

<style>
@import url("./assets/css/swiper-bundle.min.css");
@import url("./assets/css/bootstrap.min.css");
@import url("./assets/css/icomoon.css");
@import url("./assets/css/style.css");
</style>
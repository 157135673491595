<template>
      <div class='col-lg-9 col-11 mx-auto'>
        <video playsinline id="example_video_1" ref="videoPlayer" class='video-js vjs-default-skin vjs-9-16' preload='auto' poster='' data-setup='{controls:true}' >
          <source v-bind:src="videoUrl" />
        </video>
      </div>
</template>

<script>
import videojs from "video.js"
export default {
  name: 'VideoPlayer',
  props: ['videoUrl'],
  data(){
    return{
			id:"",
      player: null,
      options: {
        aspectRatio: '9:16',
        autoplay: false,
        controls: true,
        sources: [
          {
            src: "",
            type: "application/x-mpegURL",
          },
        ],
      }
    }
  },
  mounted() {
    this.options.sources[0].src = this.videoUrl;
    this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
      console.log('onPlayerReady', this);
    })
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>

<style scoped>

</style>
<template>
		<video ref="videoPlayer" class='video-js vjs-default-skin vjs-9-16' preload='auto' poster='' data-setup='{controls:true}' playsinline @click="playorPauseVideo()">
			<source v-bind:src="videoUrl" />
		</video>
</template>

<script>
import videojs from "video.js"
export default {
	name: 'VideoPlayerCustom',
	props: ['videoUrl'],
	data(){
		return{
			id:"",
			player: null,
			options: {
				aspectRatio: '9:16',
				autoplay: false,
				controls: true,
				playsinline: true,
				fluid: true,
				responsive: true,
				inactivityTimeout: 500,
				preload: 'auto',
				techOrder: ['html5'],
				sources: [
					{
						src: "",
						type: "application/x-mpegURL",
					},
				],
			}
		}
	},
	mounted() {
		this.options.sources[0].src = this.videoUrl;
		//this.options.sources[0].type = this.options.sources.type;
		this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
			//console.log('onPlayerReady', this);
		})
	},
	beforeUnmount() {
		if (this.player) {
			this.player.dispose()
		}
	},
	methods: {}
}
</script>

<style scoped>

</style>
<script>
export default {
	name: "RegisterInfo",
	methods: {
		returnHome(){
			location.href = "/"
		}
	}
}
</script>
<template>
	<div class="popup-wrapper">
		<div class="popup-content">
			<div class="image-box mb-3">
				<svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="62.5" cy="62.5" r="59.5" stroke="#999999" stroke-width="6"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M63 102C67.3651 102 71.0317 98.4013 71.0317 94.1171C71.0317 89.833 67.3651 86.2343 63 86.2343C58.6349 86.2343 54.9683 89.833 54.9683 94.1171C54.9683 98.4013 58.6349 102 63 102ZM63 23C69.1111 23 74 27.7983 74 33.7961C74 39.7939 69.1111 80.9219 63 80.9219C56.8889 80.9219 52 39.7939 52 33.7961C52 27.7983 56.8889 23 63 23Z" fill="#999999"/>
				</svg>
			</div>
			<p class="mb-3">Kayıt işleminiz başarılı bir şekilde alınmıştır. İnceleme sonrası SuperClick ekibi sizinle iletişime geçecektir.</p>
			<div class="d-flex offcanvas-account justify-content-center">
				<router-link  to="/login" class="d-inline-block btn btn-sm btn-outline-light fw-semibold rounded-5 shadow-none">Giriş Yap</router-link>
				<router-link  to="/register" class="d-inline-block btn btn-sm btn-primary fw-semibold rounded-5 shadow-none">Kayıt Ol</router-link>
			</div>
			<span @click="returnHome()" class="btn-close"></span>
		</div>
	</div>
</template>
<style scoped>
.popup-wrapper{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #000000;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99999;
}
.popup-content{
	width: 60%;
	height: 60%;
	background: #495057;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 32px;
	color: #ffff;
	border-radius: 4px;
	position: relative;
}
.image-box{
	width: 80px;
	height: 80px;
	overflow: hidden;
}

svg{
	width: 100%;
	height: 100%;
}

p{
	text-align: center;
}

.link-light{
	color: #ffffff;
	text-decoration: underline;
	text-align: center;
}

.btn-close {
	position: absolute;
	top: 24px;
	right: 24px;
	cursor: pointer;
	filter: invert(1);
}
</style>
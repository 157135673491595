<script>
/* eslint-disable */
import VideoPlayerCustom from "@/components/VideoPlayerCustom";
import axios from "axios";
import {mapMutations, mapState} from "vuex";
import SimpleTableProfile from "@/components/SimpleTableProfile";
import video1 from "../../assets/videos/1/1.mp4"

export default {
	name: "MyEvents",
	components:{
		VideoPlayerCustom,
		SimpleTableProfile
	},
	data(){
		return{
			muted: false,
			userVideos: [],
			userSoldProducts: [],
			userEvents: [],
			section: 1,
			userName: "",
			video1: video1,
			userImage:""
		}
	},
	methods: {
		...mapMutations([
			'SET_SPINNER_STATUS'
		]),
		eventDetail(eventId){
			location.href = "/event-detail/"+eventId
		},
		async getUserProfile() {
			try {
				this.SET_SPINNER_STATUS(true);
				const data = await axios.get(
					'https://prod-azure-superclick-api.clickmelive.com/api/v1/me',
					{
						headers: {
							'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
							'Token': window.sessionStorage.getItem("Token")
						}
					}
				)
				this.userImage = data.data.image;
				this.userName = data.data.username;
				this.SET_SPINNER_STATUS(false);
				
			}catch (error) {
				console.log(error)
			}
		},
		async fetchAllEvents() {
			try {
				this.SET_SPINNER_STATUS(true);
				const data = await axios.get(
					'https://prod-azure-superclick-api.clickmelive.com/api/v1/event?page=1',
					{
						headers: {
							'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
						}
					}
				)
				let eventArr = [];
				if (eventArr.indexOf(data.data.events[i].eventId) == -1) {
					eventArr.push(data.data.events[i]);
				}
				this.editUserEvents(eventArr);
				this.SET_SPINNER_STATUS(false);
			}catch (error) {
				console.log(error)
			}
		},
		editUserEvents(events) {
			const eventsArray = [];
			const eventIds = [];
			for (let index = 0; index < events.length; index++) {
				if (eventIds.indexOf(events[index].eventId) == -1) {
					const element = events[index];
					let eventImage = element.image.split("//");
					
					if (eventImage[1] === '') {
						element.image = "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg"
					}
					let endingDate = new Date(element.endingDate);
					element.endingDate = endingDate.toLocaleDateString();
					let startingDate = new Date(element.startingDate);
					element.startingDate = startingDate.toLocaleDateString();
					eventIds.push(element.eventId);
					eventsArray.push(element);
				}
			}
			this.userEvents = eventsArray;
			
		},
	},
	computed:{
		getUserInfoStore(){
			return this.$store.getters.getUserInfo
		}
	},
	mounted() {
		this.getUserProfile();
		this.fetchAllEvents();
		window.document.dispatchEvent(new Event("DOMContentLoaded", {
			bubbles: true,
			cancelable: true
		}));
	}
}
</script>
<template>
	<div>
		<section class="bg-gray-800 bg-gray-height d-flex align-items-center">
			<div class="container-xxl">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb mb-0">
						<li class="breadcrumb-item fw-semibold active" aria-current="page">Etkinliklerim</li>
					</ol>
				</nav>
			</div>
		</section>
		<!-- PROFILE -->
		<section class="profile">
			<div class="container-xxl">
				<div class="profile-block d-flex flex-wrap align-items-center">
					<div class="profile-image">
						<img v-if="userImage" :src="userImage" alt="Image" class="rounded-circle w-100 h-100">
						<img v-if="!userImage" src="../../assets/img/profile-default.png" alt="Image" class="rounded-circle w-100 h-100">
					</div>
					<div class="profile-info">
						<div class="profile-name d-flex align-items-center">
							<h3 class="fw-semibold mb-0 mt-3">@{{userName}}</h3>
							<button type="button" class="btn btn-edit border-0 rounded-0 p-0 shadow-none">
								<router-link to="/edit-profile" class="d-block fw-semibold text-decoration-none" id="mobileEvent"><img src="../../assets/img/icons/edit.svg" alt="Edit"></router-link>
							</button>
						</div>
						<ul class="profile-info-list list-inline mb-0">
							<li class="list-inline-item">
								<a href="#" class="d-block text-decoration-none fw-semibold">{{userVideos.length}} <span class="fw-normal">video</span></a>
							</li>
							<li class="list-inline-item">
								<a href="#" class="d-block text-decoration-none fw-semibold">{{userSoldProducts.length}} <span class="fw-normal">ürün</span></a>
							</li>
							<li class="list-inline-item">
								<a href="#" class="d-block text-decoration-none fw-semibold">{{userEvents.length}} <span class="fw-normal">etkinlik</span></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		<!-- etkinlikler -->
		<section class="events">
			<div class="container-xxl">
				<!--desktop-->
				<div class="border-gray-600 rounded-10 d-none d-md-block">
					<div class="row">
						<div class="col-12">
							<div class="col-12">
								<SimpleTableProfile :tableData="userEvents"  />
							</div>
						</div>
					</div>
				</div>
				<!--mobile-->
				<div class="row d-md-none">
					<div class="col-12">
						<div class="events-table-list">
							<template v-for="event in userEvents">
								<div class="events-mobile-table position-relative">
									<div class="d-flex align-items-center">
										<strong clas="fw-semibold">{{event.name}}</strong>&nbsp;&nbsp;
										<span v-if="event.type !== 4" class="badge bg-slate-blue fw-medium rounded-5 shadow-none">Açık</span>
										<span v-if="event.type==2 || event.type==4" class="badge fw-medium rounded-5 bg-slate-blue bg-red">Tamamlandı</span>
									</div>
									<div class="d-flex align-items-center">
										<span class="fw-medium event-date">Bitiş Tarihi:&nbsp;&nbsp;<span>{{event.endingDate}}</span></span>
										<span class="badge bg-yellow fw-medium d-inline-block position-relative rounded-0 ms-auto">Ücretsiz</span>
									</div>
									<div class="d-flex align-items-center">
										<span class="fw-medium total-prize">Toplam Ödül:&nbsp;&nbsp;<span class="fw-semibold color-violet">{{event.awardFee.toLocaleString("tr-TR")}} ₺</span></span>
										<!-- <span class="participation fw-semibold ms-auto">2/10</span> -->
									</div>
									<div class="d-flex align-items-center">
										<span v-if="event.type==0" class="badge fw-medium rounded-5 bg-slate-blue bg-green" >Yakında!</span>
										<button v-if="event.type==1 || event.type==2" @click="eventDetail(event.eventId)" type="button" class="btn btn-tomato-sm fw-medium rounded-5 shadow-none p-1">Video Yükle</button>
										<span v-if="event.type==4 || event.type==3" class="badge fw-medium rounded-5 bg-slate-blue bg-red">Tamamlandı</span>                  </div>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- APP-DOWNLOAD -->
		<section class="app-download">
			<div class="container-xxl">
				<div class="bg-gradient-2 rounded-20 position-relative overflow-hidden">
					<div class="row">
						<div class="app-info col-md-5 offset-md-1 col-10 position-relative text-center text-md-start">
							<h2 class="fw-bold">ClickMeLive Video <br> Alışveriş</h2>
							<p>Sosyal medya fenomenleri ve birbirinden renkli sunucuların anlatımıyla ürünleri telefonundan canlı izle. Arkadaşlarınla, sevdiklerinle paylaş ve video alışveriş keyfini birlikte yaşa.</p>
						</div>
						<div class="app-store-col col-md-5 col-11 mx-md-0 mx-auto">
							<div class="d-flex flex-column h-100">
								<div class="app-stores d-flex flex-nowrap mt-md-auto ms-auto me-auto me-md-0">
									<a target="_blank" href="https://apps.apple.com/uz/app/clickmelive/id1571757578" class="d-inline-block app-store-link" style="margin-right: 8px;"><img src="../../assets/img/icons/app-store.svg" alt="Image"></a>
									<a target="_blank" href="https://play.google.com/store/apps/details?id=com.clickme.clickmelive&gl=TR" class="d-inline-block app-store-link"><img src="../../assets/img/icons/playmarket.svg" alt="Image"></a>
								</div>
							</div>
						</div>
					</div>
					<div class="circle-left position-absolute top-50 translate-middle-y">
					</div>
					<div class="circle-right position-absolute">
					</div>
					<div class="circle-center position-absolute"></div>
				</div>
			</div>
		</section>
	</div>
</template>

<style scoped>
.video-image-boxes .image-box {display: flex;justify-content: center;align-items: center;}

.video-image-boxes .image-box img {max-width: 100%;height: auto !important;}
.video-modal .modal-body {
	padding: 0;
}
.video-modal .modal-dialog {
	margin: 10px 0;
}

.swiper-video .video-block,
.swiper-video .video-js {
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 100vh;
}

.swiper-video video {
	width: 100%;
	height: auto;
	max-height: 100%;
}

span.btn-modal-close {
	position: fixed;
	top: 8px;
	right: 8px;
	width: 45px;
	height: 45px;
	overflow: hidden;
	z-index: 1;
	cursor: pointer;
}

span.btn-modal-close svg {
	width: 100%;
	height: 100%;
	cursor: pointer;
}
.video-modal {
	z-index: 99999 !important;
	background: rgb(0, 0, 0, 90%);
}

div#vjs_video_3 {
	width: 100%;
}

button.vjs-big-play-button {
	top: 50% !important;
	left: 50% !important;
	font-size: 1.5em !important;
	width: 36px !important;
	height: 36px !important;
	margin-left: -22.5px;
	margin-top: -12px;
	line-height: 36px !important;
	border-radius: 50% !important;
	padding: 0 !important;
}

.icon-trash{
	background-color: var(--gray-900);
	width: 33px;
	height: 33px;
	border-radius: 100%;
	font-size: 16px;
	color: var(--white);
	line-height: 33px;
	text-align: center;
	display: block;
	top: 10px;
	right: 6px;
}
span.btn-mute-modal {
	width: 45px;
	height: 45px;
	overflow: hidden;
	position: fixed;
	top: 60px;
	right: 8px;
	z-index: 1;
	cursor: pointer;
}

span.btn-mute-modal svg {
	width: 100%;
	height: 100%;
}
.modal-slider-button-next {
	width: 48px !important;
	height: 48px !important;
	position: fixed;
	right: 8px;
}

.modal-slider-button-next:after {
	font-size: 1rem !important;
}

.modal-slider-button-prev {
	width: 48px !important;
	height: 48px !important;
	position: fixed;
	left: 8px;
}

.modal-slider-button-prev:after {
	font-size: 1rem !important;
}

.video.position-relative.event-videos > div {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	opacity: 0;
}
/*video yükleme popup*/
.video-image-boxes {
	width: 182px;
	margin: 12px 0 0 0 !important;
	height: 319px !important;
	display: block !important;
	border-radius: 8px;
}

.video-image-boxes > div {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}

.video-image-boxes .image-box {
	max-height: 100%;
	overflow: hidden;
	background-position: center !important;
	position: relative;
}

.video-image-boxes form.image-upload {
	padding: 0 !important;
	margin: 0 !important;
	height: 100% !important;
	max-height: 100% !important;
}

.video-image-boxes form.image-upload label {
	display: block;
	margin: 0;
	height: 100%;
	opacity: 0;
}
div#videoContentModal {
	z-index: 9999;
	background: #000;
}
.video-image-boxes .image-box > span[role="button"] {
	right: 12px;
	top: 12px;
}

.video-image-boxes .image-box > span[role="button"]:hover svg path {
	fill: red !important;
}

/*yeni play butonu*/
span.btn-open-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -24px;
	width: 48px;
	height: 66px;
	margin-top: -24px;
	z-index: 2;
}

span.btn-open-modal:before {
	content: "\e90c";
	font-family: 'icomoon';
	color: #fff;
	font-size: 3rem;
	/* position: absolute; */
	left: 50%;
	top: 46%;
	margin-left: 0px;
	margin-top: 0;
	cursor: pointer;
}

.video-modal .modal-dialog {
	margin: 0 !important;
	padding: 0 !important;
	border: thin solid;
	width: 100%;
	height: 100%;
	max-width: 100%;
	overflow: hidden;
}

.video-modal .modal-dialog .container-fluid {
	padding: 0;
}

.video-modal .modal-dialog .video-js {
	width: 100vw !important;
	height: 100vh !important;
}

.vjs-control-bar{
	visibility: hidden !important;
}
.winner-info .video{
	position: relative;
}
span.btn-trash{
	position: absolute;
	top: 8px;
	right: 8px;
}

.btn-playorpause{
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -24px;
	margin-left: -24px;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	border: 1px solid #fff;
}

.btn-playorpause:before {
	content: "\e90c";
	font-family: 'icomoon';
	color: #fff;
	position: absolute;
	left: 0;
	top: -13px;
	font-size: 3em;
	cursor: pointer;
}

div#uploadedVideoModal {
	z-index: 99999;
	background: #000;
}
</style>
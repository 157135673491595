<script>
/* eslint-disable */
import axios from "axios"
  import "animate.css"
  import Swal from "sweetalert2"
  import {mapMutations} from "vuex";
  export default {
    name: 'LoginPage',
    data(){
      return{
        params: {
          phone: ""
        },
      }
    },
    methods: {
      ...mapMutations([
        'SET_SPINNER_STATUS',
        'SET_USER_CREDENTIAL'
      ]),
      createCode(){
				;
				let phone = this.params.phone;
				phone = phone.replace("(", "")
				phone = phone.replace(")", "")
				phone = phone.replace(" ", "")
				phone = phone.replace("-", "")
				let thiz = this;
        if (this.params.phone === "" || this.params.phone === null || this.params.phone === undefined) {
          Swal.fire({
            title: 'Telefon numarası alanı boş geçilemez!',
            icon: 'warning',
            confirmButtonText: 'Anladım',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          })
        }else{
          this.SET_SPINNER_STATUS(true);
          axios.post('https://prod-azure-superclick-api.clickmelive.com/api/v1/authorization/code',
						{
							phone: phone
						},
            {
              headers: {
                "Authorization": "apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354"
              }
            }
          )
          .then(function (response) {
            console.log(response);
            thiz.SET_SPINNER_STATUS(false);
            if (localStorage.getItem("userCredentials")) {
              localStorage.removeItem("userCredentials")
            }
            thiz.SET_USER_CREDENTIAL({"phone":response.data.phone, "verificationCodeId":response.data.verificationCodeId})
            localStorage.setItem("userCredentials", JSON.stringify({"phone": response.data.phone, "verificationCodeId": response.data.verificationCodeId}));
            location.href = "/verify"
          })
          .catch(function (error) {
            thiz.SET_SPINNER_STATUS(false);
            console.log("Hata", error);
          });
        }
      },
			handleInput (e) {
				e.target.value = this.phoneMask(e.target.value)
			},
			phoneMask (phone) {
				return phone.replace(/\D/g, '')
					.replace(/^(\d)/, '($1')
					.replace(/^(\(\d{3})(\d)/, '$1) $2')
					.replace(/(\d{3})(\d{1,5})/, '$1-$2')
					.replace(/(-\d{4})\d+?$/, '$1');
			}
    }
  }
</script>

<template>
  <!-- BG-GRAY-800 -->
  <section class="bg-gray-800 bg-gray-height d-flex align-items-center">
    <div class="container-xxl">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item fw-semibold active" aria-current="page">Giriş</li>
        </ol>
      </nav>
    </div>
  </section>
  <!-- FORMS -->
  <section class="login account-access">
    <div class="container-xxl">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <form v-on:submit.prevent="createCode()" class="custom-form login-form text-center">
            <fieldset>
              <legend class="custom-fieldset fw-bold">Telefon numaranı gir</legend>
              <div class="form-container">
                <label for="phone" class="form-label form-label-custom">Giriş yapabilmen için sana SMS ile doğrulama kodu göndereceğiz.</label>
								<!--conflict prod-->
								<input
                  type="text" 
                  class="form-control form-control-custom rounded-10 shadow-none mx-auto" 
                  id="phone" 
                  placeholder="(532) 123-4567"
                  v-model="params.phone"
									@keyup="handleInput($event)"
                >
              </div>
              <button 
                type="button" 
                class="btn btn btn-md btn-primary fw-bold rounded-12 shadow-none"
                @click="createCode()"
              >
                Kodu Gönder
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- APP-DOWNLOAD -->
	<section class="app-download">
		<div class="container-xxl">
			<div class="bg-gradient-2 rounded-20 position-relative overflow-hidden">
				<div class="row">
					<div class="app-info col-md-5 offset-md-1 col-10 position-relative text-center text-md-start">
						<h2 class="fw-bold">ClickMeLive Video <br> Alışveriş</h2>
						<p>Sosyal medya fenomenleri ve birbirinden renkli sunucuların anlatımıyla ürünleri telefonundan canlı izle.
							Arkadaşlarınla, sevdiklerinle paylaş ve video alışveriş keyfini birlikte yaşa.</p>
					</div>
					<div class="app-store-col col-md-5 col-11 mx-md-0 mx-auto">
						<div class="d-flex flex-column h-100">
							<div class="app-stores d-flex flex-nowrap mt-md-auto ms-auto me-auto me-md-0">
								<a target="_blank" href="https://play.google.com/store/apps/details?id=com.clickme.clickmelive&gl=TR"><img src="../assets/img/icons/playmarket.svg" alt="Image" class="img-fluid"></a>
								<a target="_blank" href="https://apps.apple.com/uz/app/clickmelive/id1571757578"><img src="../assets/img/icons/app-store.svg" alt="Image" class="img-fluid"></a>
							</div>
						</div>
					</div>
				</div>
				<div class="circle-left position-absolute top-50 translate-middle-y">
				</div>
				<div class="circle-right position-absolute">
				</div>
				<div class="circle-center position-absolute"></div>
			</div>
		</div>
	</section>
</template>


<script>
  import axios from "axios"
  import {mapMutations} from "vuex";
	import RegisterInfo from "@/components/RegisterInfo";
  export default {
    name: 'RegisterPage',
		components: {
			RegisterInfo
		},
    data(){
      return {
				isActive: true,
				available: true,
        params: {
          isRegister: true,
          verificationCodeId: "",
          username: "",
          fullName: "",
          email: "",
          phone: "",
          deviceInfo: {
            deviceBrand: "Apple",
            deviceModel: "Iphone 8 Plus Plus",
            osVersion: "12.0.1",
            appVersion: "1.0.0",
            uniqueToken: "abc123",
            firebaseToken: "def456"
          }
        }
      }
    },
    methods: {
      ...mapMutations([
        'SET_SPINNER_STATUS',
          'SET_USER_CREDENTIAL'
      ]),
			agreeWithMe(){
				let btn = document.getElementById("btnUserCreate")
				btn.removeAttribute("disabled")
			},
      getCredentials(){
        let userCredentials = JSON.parse(localStorage.getItem("userCredentials"))
        this.params.phone = userCredentials.phone
        this.params.verificationCodeId = userCredentials.verificationCodeId
      },
      createUser(){
				let that = this;
				that.SET_SPINNER_STATUS(true);
        //this.SET_USER_CREDENTIAL(this.params);
        //location.href = "/login";
				axios.post('https://prod-azure-superclick-api.clickmelive.com/api/v1/authorization/register',
					this.params,
					{
						headers: {
							"Authorization": "apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354"
						}
					}
				)
					.then(function (response) {
						that.SET_SPINNER_STATUS(false);
						window.sessionStorage.setItem("Token", response.data.token)
						window.sessionStorage.setItem("user", JSON.stringify(response.data.user))
						location.href = "/"
					})
					.catch(function (error) {
						that.SET_SPINNER_STATUS(false);
						if (error.response.data.code === 'USER_NOT_ACTIVE'){
							that.isActive = false;
						}
						console.log(error);
					});
	
			},
			availableUsernames(){
				let that = this;
					that.SET_SPINNER_STATUS(true);
					axios.post('https://prod-azure-superclick-api.clickmelive.com/api/v1/user/available-username',
						{
							username: this.params.username,
						},
						{
							headers: {
								"Authorization": "apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354"
							}
						}
					)
						.then(function (response) {
							that.SET_SPINNER_STATUS(false);
							if (response.data.available !== true){
								return that.available = false
							}else{
								return that.available = true
							}
						})
						.catch(function (error) {
							that.SET_SPINNER_STATUS(false);
							console.log(error);
							return that.available = false
						});
			},
			checkUsername(e){
				if (e.target.value.length > 3){
					this.availableUsernames()
				}
			}
    },
    mounted(){
      this.getCredentials()
    }
  }
</script>

<template>
<!-- BG-GRAY-800 -->
  <section class="bg-gray-800 bg-gray-height d-flex align-items-center">
    <div class="container-xxl">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item fw-semibold active" aria-current="page">Giriş</li>
        </ol>
      </nav>
    </div>
  </section>
  <!-- FORMS -->
  <section class="login account-access">
    <div class="container-xxl">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <form class="custom-form register-form text-center">
            <fieldset>
              <legend class="custom-fieldset fw-bold">Üyelik Oluşturun</legend>
              <div class="form-container">
                <input 
                  type="text" 
                  class="form-control form-control-custom rounded-10 shadow-none mx-auto" 
                  id="fullName"
                  v-model="params.fullName" 
                  placeholder="Ad Soyad*"
                >
              </div>
              <div class="form-container">
                <input 
                  type="username" 
                  class="form-control form-control-custom rounded-10 shadow-none mx-auto" 
                  id="username" 
                  v-model="params.username"
                  placeholder="Kullanıcı Adı*"
									@keyup="checkUsername($event)"
                >
								<span v-if="!available" class="error-msg"><strong>{{this.params.username}}</strong> mevcut bir kullanıcı tarafından kullanılmaktadır.</span>
              </div>
              <div class="form-container">
                <input 
                  type="email" 
                  class="form-control form-control-custom rounded-10 shadow-none mx-auto" 
                  id="email" 
                  v-model="params.email"
                  placeholder="E-mail Adresi*"
                >
              </div>
              <div class="form-check mx-auto text-start">
                <input @click="agreeWithMe()" class="form-check-input shadow-none" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                  <a href="#" class="fw-semibold text-decoration-none">Üyelik Sözleşmesi'</a>ni okudum, <br>
                  kabul ediyorum.
                </label>
              </div>
              <button
								disabled="disabled"
                @click="createUser()"
                type="button" 
                class="btn btn btn-md btn-primary fw-bold rounded-12 shadow-none"
								id="btnUserCreate"
							>
                Kaydol
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- APP-DOWNLOAD -->
	<section class="app-download">
		<div class="container-xxl">
			<div class="bg-gradient-2 rounded-20 position-relative overflow-hidden">
				<div class="row">
					<div class="app-info col-md-5 offset-md-1 col-10 position-relative text-center text-md-start">
						<h2 class="fw-bold">ClickMeLive Video <br> Alışveriş</h2>
						<p>Sosyal medya fenomenleri ve birbirinden renkli sunucuların anlatımıyla ürünleri telefonundan canlı izle.
							Arkadaşlarınla, sevdiklerinle paylaş ve video alışveriş keyfini birlikte yaşa.</p>
					</div>
					<div class="app-store-col col-md-5 col-11 mx-md-0 mx-auto">
						<div class="d-flex flex-column h-100">
							<div class="app-stores d-flex flex-nowrap mt-md-auto ms-auto me-auto me-md-0">
								<a target="_blank" href="https://play.google.com/store/apps/details?id=com.clickme.clickmelive&gl=TR"><img src="../assets/img/icons/playmarket.svg" alt="Image" class="img-fluid"></a>
								<a target="_blank" href="https://apps.apple.com/uz/app/clickmelive/id1571757578"><img src="../assets/img/icons/app-store.svg" alt="Image" class="img-fluid"></a>
							</div>
						</div>
					</div>
				</div>
				<div class="circle-left position-absolute top-50 translate-middle-y">
				</div>
				<div class="circle-right position-absolute">
				</div>
				<div class="circle-center position-absolute"></div>
			</div>
		</div>
	</section>
	<div v-if="!isActive">
		<RegisterInfo/>
	</div>
</template>


<script>
/* eslint-disable */
import {mapMutations, mapState} from "vuex";

export default {
	name: "PurchaseComponent.vue",
	data(){
		return{
			isVisibleAlert: false,
		}
	},
	props:{
		isVisible: Boolean
	},
  computed:{
    getIsRedirect() {
      return this.$store.getters.getRedirectStatus;
    }
  },
	methods:{
    ...mapMutations([
      'SHOW_REDIRECT'
    ]),
		closeRedirectAlert(){
      this.SHOW_REDIRECT(false);
    }
	},
	mounted() {}
}
</script>
<template>
	<div v-if="getIsRedirect" class="alert shop-alert d-flex align-items-center alert-dismissible position-fixed top-0 bottom-0 start-0 end-0 fade show mb-0 p-0" role="alert">
		<div class="container-xxl">
			<div class="shop-alert-bg position-relative">
				<button @click="closeRedirectAlert()" type="button" class="btn-close p-0 shadow-none" data-bs-dismiss="alert" aria-label="Close"></button>
				<div class="row">
					<div class="col-12 position-relative text-end">
					</div>
					<div class="shop-alert-1 col-xl-7 col-lg-8 col-md-10 col-11 mx-auto text-center">
						<i class="icon-cart-lg d-none d-md-inline-block"></i>
						<i class="icon-cart-sm d-md-none d-inline-block"></i>
						<h2 class="alert-heading fw-semibold">Ürün ClickMeLive sepetine <br> eklendi.</h2>
						<h4 class="fw-semibold">Ürünü satın almak için uygulamayı aç ve alışverişi tamamla.</h4>
						<a target="_blank" href="https://apps.apple.com/uz/app/clickmelive/id1571757578" class="d-inline-block app-store-link" style="margin-right: 8px;"><img src="../assets/img/icons/app-store.svg" alt="Image"></a>
						<a target="_blank" href="https://play.google.com/store/apps/details?id=com.clickme.clickmelive&gl=TR" class="d-inline-block app-store-link"><img src="../assets/img/icons/playmarket.svg" alt="Image"></a>
						<h3 class="fw-medium">Satın alma işleminden sonra etkinliğe katılımın <br> onaylanacak!</h3>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.alert {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9999999999;
	background: #000;
}

.container-xxl {
	background: #262626;
	padding: 16px 24px;
	width: 90%;
}

.container-xxl * {
	color: #fff;
}

i.icon-cart-sm.d-md-none.d-inline-block {
}

.icon-cart-sm:before,
.icon-cart-lg:before{
	font-size: 8rem;
	margin: 16px 0;
	display: block;
}
#shopAlert.closed{
	display: none;
}
</style>
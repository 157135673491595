<script>
/* eslint-disable */
import {mapMutations, mapState} from "vuex";

export default {
	name: "PurchaseComponent.vue",
	data(){
		return{
			isVisibleAlert: false,
		}
	},
	props:{
		isVisible: Boolean
	},
  computed:{
    getIsAddBasket() {
      let flag = this.$store.getters.getIsAddBasket;
      if(flag){
        setTimeout(() =>  this.SHOW_ADD_BASKET(false),1000);

      }
      return flag;
    }
  },
	methods:{
    ...mapMutations([
      'SHOW_REDIRECT',
        'SHOW_ADD_BASKET'
    ]),
		closeRedirectAlert(){
      this.SHOW_ADD_BASKET(false);
    }
	},
	mounted() {}
}
</script>
<template>
	<div v-if="getIsAddBasket" class="alert shop-alert d-flex align-items-center alert-dismissible position-fixed top-0 bottom-0 start-0 end-0 fade show mb-0 p-0" role="alert">
		<div class="container-xxl">
			<div class="shop-alert-bg position-relative">
				<button @click="closeRedirectAlert()" type="button" class="btn-close p-0 shadow-none" data-bs-dismiss="alert" aria-label="Close"></button>
				<div class="row">
					<div class="col-12 position-relative text-end">
					</div>
					<div class="shop-alert-1 col-xl-7 col-lg-8 col-md-10 col-11 mx-auto text-center">
						<i class="icon-cart-lg d-none d-md-inline-block"></i>
						<i class="icon-cart-sm d-md-none d-inline-block"></i>
						<h2 class="alert-heading fw-semibold">Ürün ClickMeLive sepetine <br> eklendi.</h2>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.alert {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9999999999;
	background: #000;
}

.container-xxl {
	background: #262626;
	padding: 16px 24px;
	width: 90%;
}

.container-xxl * {
	color: #fff;
}

i.icon-cart-sm.d-md-none.d-inline-block {
}

.icon-cart-sm:before,
.icon-cart-lg:before{
	font-size: 8rem;
	margin: 16px 0;
	display: block;
}
#shopAlert.closed{
	display: none;
}
</style>
<script>
/* eslint-disable */
import {mapState, mapMutations, useStore} from "vuex";
import pembe from "../assets/img/shop/thumbs/pembe.jpg"
import turuncu from "../assets/img/shop/thumbs/turuncu.jpg"
import rose from "../assets/img/shop/thumbs/rose.jpg"
import AddBasketModal from "../components/AddBasketModal";
import axios from "axios";
import {computed} from "vue";
import store from "@/store";
export default {
	name: "ProductDetail",
	components: {
    AddBasketModal
	},
	setup(){
		const store = useStore();
		const currentBasketItems = computed(() => {
			return store.state.shoppinCartData;
		})
		
		return{
			currentBasketItems
		}
	},
	data(){
		return{
			currentItemIndex: "",
			product: [],
			productSize: [],
			currentSize: {},
			productId: "",
			eventId: "",
			shoppinCartData: {},
			isVisible: false,
			isLoading: true,
			currentCartItems: [],
      isStockControl: true,
      isStockControlNoVariant: true,
      stockValue: 0
		}
	},
	methods:{
		...mapMutations([
			"ADD_CART_DATA",
      'ADD_PRICE_INFO',
        'SHOW_ADD_BASKET',
        'SET_SPINNER_STATUS'
    ]),
		async getCurrentBasket(){
			try {
			  this.SET_SPINNER_STATUS(true);
				const data = await axios.get(
					'https://prod-azure-superclick-api.clickmelive.com/api/v1/basket?stockControl=true',
					{
						headers: {
							'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
							'Token': window.sessionStorage.getItem("Token")
						}
					}
				)
        this.SET_SPINNER_STATUS(false);

        let result = data.data.seller;
				for (let i = 0; i < result.length; i++) {
					let elem = result[i];
					for (let c = 0; c < elem.items.length; c++){
						let product = elem.items[c]
						this.currentCartItems.push(product)
					}
				}
				return;
			} catch (error) {
				console.log(error)
			}
		},
		retrieveProductId(){
			let url = new URL(window.location.href)
			url = url.href.split("/")
			this.productId = Number(url[4])
			this.eventId = localStorage.getItem("eventId")
			
		},
		async fetchProducts(){
			try {
				this.isLoading = true;
        this.SET_SPINNER_STATUS(true);
        const data = await axios.get(
					'https://prod-azure-superclick-api.clickmelive.com/api/v1/event/'+this.eventId+'/product?page=1',
					{
						headers: {
							'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
							'Token': window.sessionStorage.getItem("Token")
						}
					}
				)
        this.SET_SPINNER_STATUS(false);
        let products = data.data.products;
				let currentProduct = []
				let productSizes = []
				for (let i = 0; i < products.length; i++) {
					let elem = products[i]
					if (elem.productId === this.productId){
						elem.image = elem.image
						currentProduct.push(elem)
						elem.variants.forEach((item, index) => {
							productSizes.push(item)
						})
						
					}
				}
				this.product = currentProduct;
				this.productSize = productSizes;
				this.currentSize = this.productSize[0]?.sub;
				if(productSizes.length > 0){
				  setTimeout(() => {
            this.addShoppingData(0,null,null);
          },1500);
        } else {
				  this.stockValue = this.product[0].stockQuantity;
				  this.isStockControlNoVariant = this.stockValue > 0 ? true : false;
        }
				return this.isLoading = false;
			}catch (error) {
				return this.isLoading = false;
			}
		},
		changeSize(e) {
			this.currentSize = this.productSize[e].sub;
			this.product[0].image = this.productSize[e].ext.image
      this.addShoppingData(e,null,true); //@sc
			return this.product[0].image
		},
		async basketAddItem(item){
			try {
				this.isLoading = true;
        this.SET_SPINNER_STATUS(true);
        const data = await axios.post(
					'https://prod-azure-superclick-api.clickmelive.com/api/v1/basket/item',
					item,
					{
						headers: {
							'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
							'Token': window.sessionStorage.getItem("Token")
						},
					}
				)
        if(data.status == 200 || data.status == 201){
          this.ADD_PRICE_INFO(data);
          this.SHOW_ADD_BASKET(true);

        }
        this.SET_SPINNER_STATUS(false);

        // let lastItem = data.data.seller[0].items[data.data.seller[0].items.length-1];
				// let result = lastItem;
				// let basketItem = {};
				// basketItem.basketItemId = result.basketItemId;
				// basketItem.image = result.product.image;
				// basketItem.name = result.product.name;
				// basketItem.discountedPrice = result.product.discountedPrice;
				// basketItem.price = result.product.price;
				// basketItem.productId = result.product.productId;
				// basketItem.color = result.variants[0];
				// basketItem.size = result.variants[1];
				// this.addToCart(data.data.seller[0].items)
        store.dispatch("fetchCurrentBasket");
        return this.isLoading = false
			}catch (error) {
				return this.isLoading = false;
			}
		},
		addToCart(addedItem){
			this.ADD_CART_DATA(addedItem)
		},
		addShoppingData(i, e, bugFlag){
			e = event;
			this.currentItemIndex = i;
			let activeLabels = document.querySelectorAll("label.active");
			activeLabels = Array.from(activeLabels)
			activeLabels.forEach((item => item.classList.remove("active")))
      if(!e || (e && bugFlag)){
        this.currentItemIndex = 0;
        setTimeout(() => {
          let firstSize = document.querySelector(".size-buttons").children[0].children[1];
          firstSize.classList.add("active");
          this.isStockControl =  this.currentSize[this.currentItemIndex].stockQuantity ? true : false;
          this.stockValue = this.currentSize[this.currentItemIndex].stockQuantity;
        },500);
        return;
      }
			if (e.target.classList.contains("active")){
				event.target.classList.remove("active")
			}else{
				event.target.classList.add("active")
			}
      this.isStockControl =  this.currentSize[this.currentItemIndex].stockQuantity ? true : false;
      this.stockValue = this.currentSize[this.currentItemIndex].stockQuantity;
		},
		addNoneVariantToCart(){
			if (this.currentItemIndex === undefined || this.currentItemIndex === ""){
				this.currentItemIndex = null
			}
			if (this.currentItemIndex === null) {
				this.shoppinCartData.productId = this.productId
				this.shoppinCartData.variantId = null
				this.shoppinCartData.scEventId = Number(this.eventId)
				if (this.currentCartItems.length > 0){
					if (this.currentCartItems.some((item) => item.productId !== this.shoppinCartData.productId)){
						this.basketAddItem(this.shoppinCartData)
					}
				}else{
					this.basketAddItem(this.shoppinCartData)
				}
			}else{
				this.shoppinCartData.productId = this.currentSize[this.currentItemIndex].productId
				this.shoppinCartData.variantId = this.currentSize[this.currentItemIndex].variantId
				this.shoppinCartData.scEventId = Number(this.eventId)
				if (this.currentCartItems.length > 0) {
					if (this.currentCartItems.some((item) => item.productId !== this.currentSize[this.currentItemIndex].productId)){
						this.basketAddItem(this.shoppinCartData)
					}
				}else {
					this.basketAddItem(this.shoppinCartData)
				}
			}
		},
    addVariantToCart(){
			if (this.currentItemIndex === undefined || this.currentItemIndex === ""){
				this.currentItemIndex = null
			}
			if (this.currentItemIndex === null) {
				this.shoppinCartData.productId = this.productId
				this.shoppinCartData.variantId = null
				this.shoppinCartData.scEventId = Number(this.eventId)
				if (this.currentCartItems.length > 0){
					if (this.currentCartItems.some((item) => item.productId !== this.shoppinCartData.productId)){
						this.basketAddItem(this.shoppinCartData)
					}
				}else{
					this.basketAddItem(this.shoppinCartData)
				}
			}else{
				this.shoppinCartData.productId = this.currentSize[this.currentItemIndex].productId
				this.shoppinCartData.variantId = this.currentSize[this.currentItemIndex].variantId
				this.shoppinCartData.scEventId = Number(this.eventId)
				if (this.currentCartItems.length > 0) {
					if (this.currentCartItems.some((item) => item.productId !== this.currentSize[this.currentItemIndex].productId)){
						this.basketAddItem(this.shoppinCartData)
					}
				}else {
					this.basketAddItem(this.shoppinCartData)
				}
			}
		}
	},
	computed:{
		...mapState([
			'shoppinCartData'
		])
	},
	mounted() {
		this.retrieveProductId();
		this.fetchProducts();
		this.getCurrentBasket();
		store.dispatch("fetchCurrentBasket")
	}
}
</script>
<template>
	<div>
		<!-- BG-GRAY-800 -->
		<section class="bg-gray-800 bg-gray-height d-flex align-items-center">
			<div class="container-xxl">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb mb-0">
						<li class="breadcrumb-item fw-semibold active" aria-current="page">Ürün Detayı</li>
					</ol>
				</nav>
			</div>
		</section>
		<!-- SINGLE PRODUCT -->
		<section v-for="pr in product" :key="pr.productId" class="single-product">
			<div class="container-xxl">
				<div class="row mb-32">
					<div class="col-md-6">
						<div class="single-product-image position-relative">
							<img :src="pr.image" alt="Pembe" class="w-100 rounded-10" id="single-product-image">
							<span class="badge bg-light fw-bold text-dark position-absolute rounded-17">Son {{stockValue}} Ürün</span>
<!--							<span v-if="pr.stockQuantity < 3 && pr.stockQuantity > 0" class="badge bg-light fw-bold text-dark position-absolute rounded-17">Son {{pr.stockQuantity}} Ürün</span>-->
						</div>
					</div>
					<div class="col-md-6">
						<div class="single-product-info d-flex flex-column h-100">
							<div class="product-name">
								<h2 class="fw-semibold">{{pr.name}}</h2>
							</div>
							<div class="product-price d-flex align-items-center" v-if="pr.scPrice">
								<span class="price fw-semibold text-white me-2">₺{{pr.scPrice.toLocaleString("tr-TR")}}</span>
								<span class="price fw-light text-white"><del>₺{{pr.price.toLocaleString("tr-TR")}}</del></span>
							</div>
              <div class="product-price d-flex align-items-center" v-if="!pr.scPrice">
                <span class="price fw-semibold text-white me-2">₺{{pr.price.toLocaleString("tr-TR")}}</span>
              </div>
							<form class="select-form d-flex flex-column h-100" id="select-form">
								<fieldset v-if="pr.variants.length > 0" class="color-fieldset">
									<legend class="fw-semibold">Renk:&nbsp;<span class="selected-color d-inline-block fw-medium"></span></legend>
									<div class="color-buttons">
										<div class="d-inline-block">
											<input
												v-for="(vr, index) in pr.variants" :key="vr.variantId"
												class="form-check-input border-0 shadow-none mt-0"
												type="radio"
												name="color"
												id="pembe"
												:value="vr.variantName"
												:aria-label="vr.variantName"
												:style="{ backgroundImage: 'url(' + vr.ext.image + ')' }"
												v-bind:checked="index === 0"
												@click="changeSize(index)"
											>
										</div>
									</div>
								</fieldset>
								<fieldset v-if="pr.variants.length > 0" class="size-fieldset">
									<legend class="fw-semibold">Beden:&nbsp;<span class="selected-size fw-medium"></span></legend>
									<div class="size-buttons">
										<span
										v-for="(sub, index) in currentSize" :key="sub.variantId">
											<input
												class="btn-check"
												type="radio"
												name="size"
												:id="sub.stockCode"
												:value="sub.stockCode"
												:aria-label="sub.variantName"
											>
											<label v-on:click="addShoppingData(index, event)" class="btn btn-dark fw-medium rounded-8 shadow-none p-0" :for="sub.variantName">{{ sub.variantName }}</label>
										</span>
										
									</div>
								</fieldset>
								<div class="single-product-description mb-3 mb-3">
									<div class="row">
										<div class="col-12 section-heading">
											<h2 class="fw-semibold">Ürün Açıklaması</h2>
											<p>{{pr.description}}</p>
										</div>
									</div>
								</div>
								<button v-if="product[0].variants.length == 0 && isStockControlNoVariant" @click="addNoneVariantToCart()" type="button" form="select-form" class="btn btn-xl btn-tomato fw-bold shadow-none w-100">Sepete Ekle</button>
								<button v-if="product[0].variants.length == 0 && !isStockControlNoVariant" disabled type="button" form="select-form" class="btn btn-xl btn-tomato fw-bold shadow-none w-100">Tükendi</button>
								<button v-if="product[0].variants.length > 0 && isStockControl" @click="addNoneVariantToCart()" type="button" form="select-form" class="btn btn-xl btn-tomato fw-bold shadow-none w-100">Sepete Ekle</button>
								<button v-if="product[0].variants.length > 0 && !isStockControl" disabled type="button" form="select-form" class="btn btn-xl btn-tomato fw-bold shadow-none w-100">Tükendi</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- APP-DOWNLOAD -->
		<section class="app-download">
			<div class="container-xxl">
				<div class="bg-gradient-2 rounded-20 position-relative overflow-hidden">
					<div class="row">
						<div class="app-info col-md-5 offset-md-1 col-10 position-relative text-center text-md-start">
							<h2 class="fw-bold">ClickMeLive Video <br> Alışveriş</h2>
							<p>Sosyal medya fenomenleri ve birbirinden renkli sunucuların anlatımıyla ürünleri telefonundan canlı izle.
								Arkadaşlarınla, sevdiklerinle paylaş ve video alışveriş keyfini birlikte yaşa.</p>
						</div>
						<div class="app-store-col col-md-5 col-11 mx-md-0 mx-auto">
							<div class="d-flex flex-column h-100">
								<div class="app-stores d-flex flex-nowrap mt-md-auto ms-auto me-auto me-md-0">
									<a target="_blank" href="https://play.google.com/store/apps/details?id=com.clickme.clickmelive&gl=TR"><img src="../assets/img/icons/app-store.svg" alt="Image" class="img-fluid"></a>
									<a target="_blank" href="https://apps.apple.com/uz/app/clickmelive/id1571757578"><img src="../assets/img/icons/playmarket.svg" alt="Image" class="img-fluid"></a>
								</div>
							</div>
						</div>
					</div>
					<div class="circle-left position-absolute top-50 translate-middle-y">
					</div>
					<div class="circle-right position-absolute">
					</div>
					<div class="circle-center position-absolute"></div>
				</div>
			</div>
		</section>
	</div>
	<!--<div v-if="isLoading" class="loader-panel"><div class="loader">Loading...</div></div>-->
	<div v-if="isVisible"></div>
  <AddBasketModal/>
</template>
<style scoped>
.form-check-input {
	background-size: cover;
}

.loader {
	color: #ffffff;
	font-size: 90px;
	text-indent: -9999em;
	overflow: hidden;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	margin: 72px auto;
	position: relative;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
	animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
	0% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	5%,
	95% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	10%,
	59% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
	}
	20% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
	}
	38% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
	}
	100% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
}
@keyframes load6 {
	0% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	5%,
	95% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	10%,
	59% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
	}
	20% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
	}
	38% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
	}
	100% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
}
@-webkit-keyframes round {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes round {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.loader-panel{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000000;
}
.form-check-input {
	margin-right: 8px;
}

.size-buttons > span {
	margin-right: 8px;
	display: inline-block;
	position: relative;
}

.size-buttons > span input {
	width: 50px;
	height: 50px;
	top: 0;
	left: 0;
}
label.btn.btn-dark.active {
	background: #8a39ff;
	color: #fff;
}

</style>
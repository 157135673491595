<script>
/* eslint-disable */
import RedirectComponent from "../components/RedirectComponent";
import {mapMutations, mapState, useStore} from "vuex";
import store from "@/store";
import {computed} from "vue";
import axios from "axios";
export default {
  name: "HeaderComponent",
	components: {
		RedirectComponent
	},
  props: {
    isAuth: Boolean,
  },
	setup(){
		const store = useStore()
		const shoppingCartData = computed(() => {
			return store.state.shoppinCartData
		})
		const currentBasketItems = computed(() => {
			return store.state.shoppinCartData;
		})
		return{
			shoppingCartData,
			currentBasketItems
		}
	},
  data(){
    return{
      user: {},
			shoppinCartData: [],
      priceInfo: {
        'araToplam': null,
        'indirimTutarı':null,
        'odenecekTutar':null
      },
			showCart: false,
			showRedirect: false,
			isVisible: false
    }
  },
  methods: {
		...mapMutations([
			"ADD_CART_DATA",
        "SET_USER_INFO"
		]),
		redirecttoLogin(){
			let bodyOverlay = document.querySelector(".backdrop-overlay")
			let canvasMenu = document.querySelector(".offcanvas-menu")
			setTimeout(() => {
				bodyOverlay.classList.contains("backdrop-overlay") ? bodyOverlay.classList.remove("backdrop-overlay") : ""
				canvasMenu.classList.contains("offcanvas-menu") ? canvasMenu.classList.remove("offcanvas-menu") : ""
			}, 1000)
			location.href = "/login"
		},
		redirecttoRegister(){
			let bodyOverlay = document.querySelector(".backdrop-overlay")
			let canvasMenu = document.querySelector(".offcanvas-menu")
			setTimeout(() => {
				bodyOverlay.classList.contains("backdrop-overlay") ? bodyOverlay.classList.remove("backdrop-overlay") : ""
				canvasMenu.classList.contains("offcanvas-menu") ? canvasMenu.classList.remove("offcanvas-menu") : ""
			}, 1000)
			location.href = "/login"
		},
    showRedirectModal(){
      this.SHOW_REDIRECT(true);
    },
		logout(){
			window.sessionStorage.removeItem("Token");
			window.sessionStorage.removeItem("user");
			location.href = "/"
		},
		...mapMutations([
			'REMOVE_CART_DATA',
        'ADD_PRICE_INFO',
        'SHOW_REDIRECT',
        'SET_SPINNER_STATUS',
        'SET_USER_INFO'
		]),
		gotoBag(){
			if (this.isVisible){
				this.isVisible = false
			}else{
				this.isVisible = true
			}
			this.showRedirect = true
		},
		removeItemFromCart(itemId){
			this.REMOVE_CART_DATA(itemId)
		},
    async getUserInfo() {
      try {
        this.SET_SPINNER_STATUS(true);
        const data = await axios.get(
            'https://prod-azure-superclick-api.clickmelive.com/api/v1/me',
            {
              headers: {
                'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
                'Token': window.sessionStorage.getItem("Token")
              }
            }
        )
        let params = {
          "userId": data.data.userId,
          "fullname": data.data.fullName,
          "username": data.data.username,
          "image": data.data.image ? data.data.image : require("@/assets/img/profile-default.png")
        }
        this.SET_USER_INFO(params);
        this.SET_SPINNER_STATUS(false);

      }catch (error) {
        console.log(error)
      }
    },
		async basketRemoveItem(basketItemId){
			try {
        this.SET_SPINNER_STATUS(true);
        const data = await axios.delete(
					'https://prod-azure-superclick-api.clickmelive.com/api/v1/basket/item/'+basketItemId,
					{
						headers: {
							"Authorization": "apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354",
							'Token': window.sessionStorage.getItem("Token")
						},
					}
				)
        this.SET_SPINNER_STATUS(false);
        if(data.status == 200 || data.status == 201){
          this.ADD_PRICE_INFO(data);
        }
				this.removeItemFromCart(basketItemId)
			}catch (error) {
				console.log(error)
			}
		},
  },
	computed:{
		...mapState([
			'shoppinCartData',
      'priceInfo'
		]),
    getPriceInfo() {
		  return this.$store.getters.getPriceInfo
    },
    getUserInfoStore(){
		  return this.$store.getters.getUserInfo
    }
	},
  mounted() {
  	this.getUserInfo()
		store.dispatch("fetchCurrentBasket")
  }
}
</script>
<template>
	<!-- OFFCANVAS-MENU -->
	<div class="d-md-none offcanvas-menu position-fixed bottom-0 end-0">
		<ul class="list-unstyled mb-0">
			<li><router-link to="/" class="d-block fw-semibold text-decoration-none" id="mobileAbout">Anasayfa</router-link></li>
			<li><router-link to="/about-us" class="d-block fw-semibold text-decoration-none" id="mobileAbout">Hakkımızda</router-link></li>
        	<li><router-link to="/etkinlikler" class="d-block fw-semibold text-decoration-none" id="mobileEvent">Etkinlikler</router-link></li>
			<!--<li><router-link to="/register" class="d-block fw-semibold text-decoration-none">Katılım Koşulları</router-link></li>-->
		</ul>
		<div class="d-flex offcanvas-account justify-content-center">
			<button @click="redirecttoLogin()" v-if="!isAuth" class="d-inline-block btn btn-sm btn-outline-light fw-semibold rounded-5 shadow-none">Giriş Yap</button>
			<button @click="redirecttoRegister()" v-if="!isAuth" class="d-inline-block btn btn-sm btn-primary fw-semibold rounded-5 shadow-none">Kayıt Ol</button>
		</div>
	</div>
	<!-- HEADER -->
	<header class="header bg-gray-900 position-fixed top-0 end-0 start-0">
		<div class="container-xxl d-flex align-items-center justify-content-between justify-content-md-start">
			<router-link to="/"><img src="../assets/img/superclick-logo-min.svg" alt="Logo" class="header-logo"></router-link>
			<ul class="header-list-inline list-inline d-none d-md-block mb-0">
				<li class="list-inline-item"><router-link to="/" id="landingPage" class="d-block fw-semibold text-decoration-none">Anasayfa</router-link></li>
				<li class="list-inline-item"><router-link to="/about-us" id="menuAbout" class="d-block fw-semibold text-decoration-none">Hakkımızda</router-link></li>
				<li class="list-inline-item"><router-link to="/etkinlikler" id="menuEvent" class="d-block fw-semibold text-decoration-none">Etkinlikler</router-link></li>
			</ul>
			<ul v-if="isAuth" class="account-list list-inline ms-auto mb-0" style="margin-right: 10px;">
				<li class="list-inline-item dropdown">
					<a href="#" class="dropdown-toggle fw-medium text-decoration-none" role="button" id="userAccountDropdown" data-bs-toggle="dropdown" aria-expanded="false">
						<img v-if="getUserInfoStore.image && getUserInfoStore.image !== null"  :src="getUserInfoStore.image" alt="Image" class="avatar-image rounded-circle mr-2">
						<img v-if="!getUserInfoStore.image || getUserInfoStore.image === null"  src="../assets/img/profile-default.png" alt="Image" class="avatar-image rounded-circle mr-2">
						<span style="margin-left: 8px;" class="fw-medium d-none d-md-inline-block">{{getUserInfoStore.fullname}}</span>
					</a>
					<ul class="dropdown-menu rounded-0 py-0" aria-labelledby="userAccountDropdown">
						<li><router-link to="/profile" class="dropdown-item fw-semibold">Profil</router-link></li>
						<!--
						<li><router-link to="/etkinliklerim" class="dropdown-item fw-semibold">Etkinliklerim</router-link></li>
						<li><router-link to="/urunlerim" class="dropdown-item fw-semibold">Ürünlerim</router-link></li>
						<li><router-link to="/videolarim" class="dropdown-item fw-semibold">Videolarım</router-link></li>
						-->
						<!--<li><router-link to="" class="dropdown-item fw-semibold" href="#">Katıldığın Etkinlikler</router-link></li>-->
						<li><router-link to="/progress-payments" class="dropdown-item fw-semibold" href="#">Hakedişler</router-link></li>
						<!--<li><router-link to="" class="dropdown-item fw-semibold" href="#">Fatura Bilgileri</router-link></li>-->
						<li><router-link @click="logout()" to="" class="dropdown-item fw-semibold" href="#">Çıkış</router-link></li>
					</ul>
				</li>
			</ul>
			<ul v-if="isAuth" class="cart-dropdown list-inline mb-0">
				<li class="list-inline-item dropdown cart-dropdown">
					<a href="#" class="dropdown-toggle position-relative fw-medium text-decoration-none" role="button" id="cartDropdown" data-bs-toggle="dropdown" aria-expanded="false">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M19.4036 3.3975C19.1633 3.1162 18.8625 2.88996 18.5225 2.73485C18.1824 2.57974 17.8116 2.49956 17.4362 2.5H3.62392L3.58804 2.2075C3.51462 1.59952 3.21505 1.03894 2.74613 0.632066C2.27721 0.225186 1.67154 0.000284828 1.04395 0L0.854296 0C0.627723 0 0.410429 0.0877975 0.250218 0.244078C0.090006 0.400358 0 0.61232 0 0.833334C0 1.05435 0.090006 1.26631 0.250218 1.42259C0.410429 1.57887 0.627723 1.66667 0.854296 1.66667H1.04395C1.2532 1.6667 1.45515 1.74163 1.61152 1.87726C1.76789 2.0129 1.86778 2.19979 1.89227 2.4025L3.06778 12.1525C3.18982 13.1665 3.68926 14.1015 4.47131 14.78C5.25336 15.4585 6.26358 15.8334 7.31021 15.8334H16.2316C16.4582 15.8334 16.6755 15.7456 16.8357 15.5893C16.9959 15.433 17.0859 15.221 17.0859 15C17.0859 14.779 16.9959 14.567 16.8357 14.4108C16.6755 14.2545 16.4582 14.1667 16.2316 14.1667H7.31021C6.78145 14.1652 6.26609 14.0043 5.83479 13.7059C5.40348 13.4075 5.07735 12.9863 4.9011 12.5H15.0843C16.0858 12.5001 17.0555 12.1569 17.8239 11.5304C18.5923 10.9039 19.1106 10.0339 19.2883 9.07251L19.9589 5.44417C20.0259 5.08418 20.0108 4.71423 19.9149 4.36054C19.8189 4.00684 19.6444 3.67807 19.4036 3.3975ZM18.2819 5.14834L17.6105 8.77668C17.5038 9.35418 17.1923 9.87667 16.7304 10.2527C16.2686 10.6287 15.6859 10.8342 15.0843 10.8333H4.62943L3.82554 4.16667H17.4362C17.5617 4.16594 17.6858 4.19219 17.7997 4.24355C17.9136 4.29491 18.0146 4.37013 18.0953 4.46384C18.176 4.55756 18.2346 4.66748 18.2669 4.78579C18.2991 4.90409 18.3043 5.02788 18.2819 5.14834Z" fill="white"/>
							<path d="M5.98008 20.0001C6.92371 20.0001 7.68867 19.2539 7.68867 18.3334C7.68867 17.4129 6.92371 16.6667 5.98008 16.6667C5.03645 16.6667 4.27148 17.4129 4.27148 18.3334C4.27148 19.2539 5.03645 20.0001 5.98008 20.0001Z" fill="white"/>
							<path d="M14.523 20.0001C15.4667 20.0001 16.2316 19.2539 16.2316 18.3334C16.2316 17.4129 15.4667 16.6667 14.523 16.6667C13.5794 16.6667 12.8145 17.4129 12.8145 18.3334C12.8145 19.2539 13.5794 20.0001 14.523 20.0001Z" fill="white"/>
						</svg>
						<span class="quantity position-absolute d-block">{{shoppingCartData.length}}</span>
					</a>
					<div class="dropdown-menu border-0" aria-labelledby="cartDropdown">
						<div v-for="data in shoppingCartData" :key="data.basketItemId" class="cart-product-item d-flex">
							<div class="product-image">
								<img :src="data.image" alt="Image" class="w-100">
							</div>
							<div class="product-info d-flex flex-column">
								<div class="product-name d-flex justify-content-between">
									<h5 class="fw-normal mb-0">{{data.name}}</h5>
									<button :data-id="data.basketItemId" @click="basketRemoveItem(data.basketItemId)" type="button" class="btn border-0 shadow-none"></button>
								</div>
								<div class="product-name d-flex justify-content-between">
									<span class="text-white">{{data.color}}</span>
									<span class="text-white">{{data.size}}</span>
								</div>
								<div class="product-price mt-auto" v-if="data.scPrice">
									<span class="text-white">{{data.scPrice}}₺</span>&nbsp;&nbsp
									<span class="text-white fw-light"><del>{{data.price}}₺</del></span>
								</div>
                <div class="product-price mt-auto" v-if="!data.scPrice">
                  <span class="text-white">{{data.price}}₺</span>
                </div>
							</div>
						</div>
						<div v-if="shoppingCartData.length > 0" class="order-summary">
							<div class="order-summary-title">
								<h4 class="mb-0 fw-semibold">Sipariş Özeti</h4>
							</div>
							<div class="order-details" v-if="getPriceInfo.odenecekTutar">
								<dl class="mb-0">
									<div v-if="getPriceInfo.araToplam" class="d-flex align-items-center justify-content-between">
                    <dt class="fw-normal">Ara Toplam</dt>
                    <dd class="fw-medium text-white mb-0">{{getPriceInfo.araToplam}} TL</dd>
									</div>
                  <div v-if="getPriceInfo.indirimTutarı" class="d-flex align-items-center justify-content-between">
                    <dt class="fw-normal">İndirim Tutarı</dt>
                    <dd class="fw-medium text-white mb-0">{{getPriceInfo.indirimTutarı}} TL</dd>
                  </div>
                  <div v-if="getPriceInfo.odenecekTutar" class="d-flex align-items-center justify-content-between">
                    <dt class="fw-normal">Ödenecek Tutar</dt>
                    <dd class="fw-medium text-white mb-0">{{getPriceInfo.odenecekTutar}} TL</dd>
                  </div>
								</dl>
							</div>
							<button type="button" class="btn btn-lg btn-tomato rounded-12 fw-bold shadow-none mx-auto d-block" @click="showRedirectModal()">Sepete Git</button>
						</div>
						<p style="width: 90%;margin: 0 auto; font-size: 0.8em !important" class="link-light" v-if="shoppingCartData.length == 0 || shoppingCartData.length == null">Sepetinizde Ürün Bulunmamaktadır.</p>
					</div>
				</li>
			</ul>
			<router-link v-if="!isAuth" to="/login" class="d-none d-md-inline-block btn btn-sm btn-outline-light fw-semibold rounded-5 shadow-none ms-auto">Giriş Yap</router-link>
			<router-link v-if="!isAuth" to="/login" class="d-none d-md-inline-block btn btn-sm btn-primary fw-semibold rounded-5 shadow-none">Kayıt Ol</router-link>
			<button type="button" class="d-md-none btn btn-hamburger border-0 rounded-0 shadow-none p-0"></button>
		</div>
	</header>
<RedirectComponent/>
</template>
<style scoped>
.product-info {width: calc(100% - 74px);}
</style>
<script>
/* eslint-disable */
import axios from "axios"
import Swal from 'sweetalert2'
import {mapMutations} from "vuex";
import RegisterInfo from "@/components/RegisterInfo";
  export default {
    name: 'LoginPage',
		components:{
			RegisterInfo
		},
    data(){
      return{
				timerValue: 120,
				isActive: true,
        params: {
          phone: "",
          code: "",
        },
        verificationCodeId: ""
      }
    },
    methods: {
      ...mapMutations([
        'SET_SPINNER_STATUS'
      ]),
			checkUserStatus(){
				let thiz = this;
				thiz.SET_SPINNER_STATUS(true);
				axios.get('https://prod-azure-superclick-api.clickmelive.com/api/v1/me',
					{
						headers: {
							"Authorization": "apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354",
							'Token': window.sessionStorage.getItem("Token")
						},
					}
				)
					.then(function (response) {
						thiz.SET_SPINNER_STATUS(false);
						console.log(response)
						return response.isActive
					})
					.catch(function (error) {
						console.log(error)
						thiz.SET_SPINNER_STATUS(false);
					});
			},
      getCredentials(){
        let userCredentials = JSON.parse(localStorage.getItem("userCredentials"))
        this.params.phone = userCredentials.phone
        this.verificationCodeId = userCredentials.verificationCodeId
      },
      verifyCode(){
				let thiz = this;
        thiz.SET_SPINNER_STATUS(true);
        axios.post('https://prod-azure-superclick-api.clickmelive.com/api/v1/authorization/code/'+this.verificationCodeId+'/verify',
          {
            "phone": this.params.phone,
            "code": this.params.code,

            "deviceInfo": {
              deviceBrand: "Apple",
              deviceModel: "Iphone 8 Plus Plus",
              osVersion: "12.0.1",
              appVersion: "1.0.0",
              uniqueToken: "abc1235",
              firebaseToken: "def456"
            }
          },
          {
            headers: {
              "Authorization": "apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354"
            }
          }
        )
        .then(function (response) {
          thiz.SET_SPINNER_STATUS(false);
          if (response.data.user !== null && response.data.token !== null) {
						window.sessionStorage.setItem("Token", response.data.token)
						window.sessionStorage.setItem("user", JSON.stringify(response.data.user))
						window.location.href = "/etkinlikler"
					}else{
						location.href = "/register"
					}
          console.log(response)
        })
        .catch(function (error) {
					console.log(error)
          thiz.SET_SPINNER_STATUS(false);
					if (error.response.data.code === 'USER_NOT_ACTIVE'){
						thiz.isActive = false;
					}else {
						Swal.fire({
							title: "Hata!",
							html: "SMS kodunu yanlış girdin. Lütfen! Tekrar dene...",
							icon: 'error',
							confirmButtonText: 'Anladım',
							showClass: {
								popup: 'animate__animated animate__fadeInDown'
							},
							hideClass: {
								popup: 'animate__animated animate__fadeOutUp'
							}
						})
					}
        });
      },
			timer(){
				setInterval(() => {
					if (this.timerValue === 0){
						return
					}else{
						this.timerValue -= 1
					}
				}, 1000)
			}
    },
    mounted(){
			this.getCredentials(),
			this.timer()
    },
    computed:{
      getUserCreateInfo() {
        return this.$store.getters.getUserCredentials;
      }
    },
  }
</script>

<template>
  <!-- BG-GRAY-800 -->
  <section class="bg-gray-800 bg-gray-height d-flex align-items-center">
    <div class="container-xxl">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item fw-semibold active" aria-current="page">Doğrulama</li>
        </ol>
      </nav>
    </div>
  </section>
  <!--verify-->
  <section class="login account-access">
    <div class="container-xxl">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <form v-on:submit.prevent="verifyCode()" class="custom-form login-form text-center">
            <fieldset>
              <legend class="custom-fieldset fw-bold">SMS Kodu gir</legend>
							<p class="countdown" style="color:#fff; border-color:#7657E5">{{timerValue}}</p>
              <div class="form-container">
                <label for="phone" class="form-label form-label-custom">Sana gönderdiğimiz kodu aşağıdaki alana girerek devam et lütfen!</label>
                  <div class="form-container">
                    <input 
                      type="text" 
                      class="form-control form-control-custom rounded-10 shadow-none mx-auto" 
                      id="phone" 
                      placeholder="Telefon Numarası"
                      v-model="params.phone"
                    >
                  </div>
                  <div class="form-container">
                    <input 
                      type="text" 
                      class="form-control form-control-custom rounded-10 shadow-none mx-auto" 
                      id="phone" 
                      placeholder="Kodu giriniz"
                      @keyup.enter="verifyCode()"
                      v-model="params.code"
                    >
                  </div>
              </div>
              <button 
                type="button" 
                class="btn btn btn-md btn-primary fw-bold rounded-12 shadow-none"
                @click="verifyCode()"
              >
                Giriş Yap
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- APP-DOWNLOAD -->
	<section class="app-download">
		<div class="container-xxl">
			<div class="bg-gradient-2 rounded-20 position-relative overflow-hidden">
				<div class="row">
					<div class="app-info col-md-5 offset-md-1 col-10 position-relative text-center text-md-start">
						<h2 class="fw-bold">ClickMeLive Video <br> Alışveriş</h2>
						<p>Sosyal medya fenomenleri ve birbirinden renkli sunucuların anlatımıyla ürünleri telefonundan canlı izle.
							Arkadaşlarınla, sevdiklerinle paylaş ve video alışveriş keyfini birlikte yaşa.</p>
					</div>
					<div class="app-store-col col-md-5 col-11 mx-md-0 mx-auto">
						<div class="d-flex flex-column h-100">
							<div class="app-stores d-flex flex-nowrap mt-md-auto ms-auto me-auto me-md-0">
								<a target="_blank" href="https://play.google.com/store/apps/details?id=com.clickme.clickmelive&gl=TR"><img src="../assets/img/icons/app-store.svg" alt="Image" class="img-fluid"></a>
								<a target="_blank" href="https://apps.apple.com/uz/app/clickmelive/id1571757578"><img src="../assets/img/icons/playmarket.svg" alt="Image" class="img-fluid"></a>
							</div>
						</div>
					</div>
				</div>
				<div class="circle-left position-absolute top-50 translate-middle-y">
				</div>
				<div class="circle-right position-absolute">
				</div>
				<div class="circle-center position-absolute"></div>
			</div>
		</div>
	</section>
	<div v-if="!isActive">
		<RegisterInfo/>
	</div>
</template>
<style scoped>
.countdown{
	text-align: center;
	width: 96px;
	height: 96px;
	line-height: 96px;
	margin: 0 auto 24px;
	border-radius: 50%;
	border: 2px solid #6583fe;
}
</style>


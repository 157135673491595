<script>
export default {
	name: "CarouselComponent",
	data(){
		return{}
	},
	methods:{},
	mounted() {}
}
</script>
<template>
	<section class="bg-gray-900">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12 col-11 mx-md-0 mx-auto">
					<section class="swiper-carousel swiper bg-gray-900">
						<div class="swiper-wrapper">
							<!--
							<div class="swiper-slide caption-bg-red h-auto position-relative">
								<a href="#"><img src="../assets/img/banners/banner-small_01.png" alt="Image" class="w-100 rounded-10"></a>
								<div class="swiper-carousel-caption position-absolute top-50 translate-middle-y">
									<h2 class="mb-0 fw-bold text-center position-relative"></h2>
								</div>
							</div>-->
							<div class="swiper-slide caption-bg-red h-auto position-relative">
								<a href="#"><img src="../assets/img/banners/banner-small_02.png" alt="Image" class="w-100 rounded-10"></a>
								<div class="swiper-carousel-caption position-absolute top-50 translate-middle-y">
									<h2 class="mb-0 fw-bold text-center position-relative"></h2>
								</div>
							</div>
							<!--
							<div class="swiper-slide caption-bg-red h-auto position-relative">
								<a href="#"><img src="../assets/img/banners/banner-small_03.png" alt="Image" class="w-100 rounded-10"></a>
								<div class="swiper-carousel-caption position-absolute top-50 translate-middle-y">
									<h2 class="mb-0 fw-bold text-center position-relative"></h2>
								</div>
							</div>-->
							<div class="swiper-slide caption-bg-red h-auto position-relative">
								<a href="#"><img src="../assets/img/banners/banner-small_04.png" alt="Image" class="w-100 rounded-10"></a>
								<div class="swiper-carousel-caption position-absolute top-50 translate-middle-y">
									<h2 class="mb-0 fw-bold text-center position-relative"></h2>
								</div>
							</div>
							<div class="swiper-slide caption-bg-red h-auto position-relative">
								<a href="#"><img src="../assets/img/banners/banner-small_05.png" alt="Image" class="w-100 rounded-10"></a>
								<div class="swiper-carousel-caption position-absolute top-50 translate-middle-y">
									<h2 class="mb-0 fw-bold text-center position-relative"></h2>
								</div>
							</div>
							<div class="swiper-slide caption-bg-red h-auto position-relative">
								<a href="#"><img src="../assets/img/banners/banner-small_06.png" alt="Image" class="w-100 rounded-10"></a>
								<div class="swiper-carousel-caption position-absolute top-50 translate-middle-y">
									<h2 class="mb-0 fw-bold text-center position-relative"></h2>
								</div>
							</div>
							<div class="swiper-slide caption-bg-red h-auto position-relative">
								<a href="#"><img src="../assets/img/banners/banner-small_07.png" alt="Image" class="w-100 rounded-10"></a>
								<div class="swiper-carousel-caption position-absolute top-50 translate-middle-y">
									<h2 class="mb-0 fw-bold text-center position-relative"></h2>
								</div>
							</div>
							<div class="swiper-slide caption-bg-red h-auto position-relative">
								<a href="#"><img src="../assets/img/banners/banner-small_08.png" alt="Image" class="w-100 rounded-10"></a>
								<div class="swiper-carousel-caption position-absolute top-50 translate-middle-y">
									<h2 class="mb-0 fw-bold text-center position-relative"></h2>
								</div>
							</div>
							<div class="swiper-slide caption-bg-red h-auto position-relative">
								<a href="#"><img src="../assets/img/banners/banner-small_09.png" alt="Image" class="w-100 rounded-10"></a>
								<div class="swiper-carousel-caption position-absolute top-50 translate-middle-y">
									<h2 class="mb-0 fw-bold text-center position-relative"></h2>
								</div>
							</div>
							<div class="swiper-slide caption-bg-red h-auto position-relative">
								<a href="#"><img src="../assets/img/banners/banner-small_10.png" alt="Image" class="w-100 rounded-10"></a>
								<div class="swiper-carousel-caption position-absolute top-50 translate-middle-y">
									<h2 class="mb-0 fw-bold text-center position-relative"></h2>
								</div>
							</div>
						</div>
						<div class="swiper-pagination position-relative"></div>
					</section>
				</div>
			</div>
		</div>
	</section>
</template>
<style scoped>
</style>
<script>
export default {
  name: "SimpleCard",
  props: {
    cardData: Array
  },
	methods: {
		eventDetail(eventId){
			location.href = "/event-detail/"+eventId
		}
	}
}
</script>
<template>
		<div class="col-12 mb-3">
			<div class="swiper-events swiper">
				<div class="swiper-wrapper">
					<div v-for="data in cardData" :key="data.eventId" class="swiper-slide h-auto">
						<div class="card event-card border-0 rounded-5 bg-gradient-3">
							<div @click="eventDetail(data.eventId)" class="card-body">
								<h6>{{data.name}}</h6>
								<div class="d-flex justify-content-between align-items-center">
									<h4 class="fw-semibold mb-0">{{data.awardFee.toLocaleString("tr-TR")}} TL</h4>
									<span class="fw-semibold">Açık</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="swiper-button-prev"></div>
				<div class="swiper-button-next"></div>
				<div class="swiper-pagination position-relative"></div>
			</div>
		</div>
</template>
<style scoped>
.swiper-events .swiper-slide:first-child > div.card {
	background: var(--gradient-3) !important;
}

.swiper-events .swiper-slide:nth-child(2n) > div.card {
	background: var(--gradient-4);
}

.swiper-events .swiper-slide:nth-child(2n+1) > div.card {
	background: var(--gradient-5);
}

.swiper-events .swiper-slide:nth-child(3n+1) > div.card {
	background: var(--gradient-6);
}
</style>
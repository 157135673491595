<script>
/* eslint-disable */
import VideoPlayerCustom from "@/components/VideoPlayerCustom";
import axios from "axios";
import {mapMutations} from "vuex";
import SimpleTable from "@/components/SimpleTable";

export default {
  name: "EditProfilePage",
  components:{
    VideoPlayerCustom,
    SimpleTable
  },
  data(){
    return{
      userInfo:{},
      photoUploadResp: null,
      photoData: null
    }
  },
  methods: {
    ...mapMutations([
      'SET_SPINNER_STATUS',
        'SET_USER_INFO'
    ]),
		redirect(pathName){
			location.href = "/"+pathName
		},
    async changeFile(){
      this.SET_SPINNER_STATUS(true);
      let file = this.$refs.photoUpload.files[0];
        let formData = new FormData();
        formData.append("file", file);
        this.photoUploadResp = await axios.post(
          'https://prod-azure-superclick-api.clickmelive.com/api/v1/upload/profile-photo',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
              'Token': window.sessionStorage.getItem("Token")
            }
          }
      )
      if(this.photoUploadResp.status == '201'){
        this.photoData = this.photoUploadResp.data;
        this.userInfo.image = this.photoData.url;
      }
      this.SET_SPINNER_STATUS(false);
    },
    async updateProfile(){
      this.userInfo["fullName"] = this.userInfo["name"] + " " + this.userInfo["surname"];
     let updateResp = await axios.put('https://prod-azure-superclick-api.clickmelive.com/api/v1/me',
          this.userInfo,
          {
            headers: {
              "Authorization": "apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354",
              'Token': window.sessionStorage.getItem("Token")
            }
          }
      )
      let params = {
        "userId": this.userInfo["userId"],
        "fullname": this.userInfo["fullName"],
        "username": this.userInfo["username"],
        "image": this.userInfo["image"]
      }
      this.SET_USER_INFO(params);
      this.$router.push("/profile");

    },
    async getUserProfile() {
      try {
        this.SET_SPINNER_STATUS(true);
        const data = await axios.get(
            'https://prod-azure-superclick-api.clickmelive.com/api/v1/me',
            {
              headers: {
                'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
                'Token': window.sessionStorage.getItem("Token")
              }
            }
        )
        let name = data.data.fullName.split(" ")[0];
        let surname = data.data.fullName.split(" ")[1];
        this.userInfo = data.data;
        this.userInfo["name"] = name;
        this.userInfo["surname"] = surname;
        this.SET_SPINNER_STATUS(false);

      }catch (error) {
        console.log(error)
      }
    }
  },
  mounted() {
    this.getUserProfile();
  }
}
</script>
<template>
  <div>
    <!-- BG-GRAY-800 -->
    <section class="bg-gray-800 bg-gray-height d-flex align-items-center">
      <div class="container-xxl">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item fw-semibold active" aria-current="page">Profil</li>
          </ol>
        </nav>
      </div>
    </section>
    <!-- PROFILE -->
    <section class="profile profile-settings">
      <div class="container-xxl">
        <div class="profile-block-row">
          <div class="row">
            <div class="col-lg-9 col-md-8">
              <div class="profile-block d-flex flex-wrap align-items-center border-bottom-0 mb-0 py-0">
                <div class="profile-image">
                  <img v-if="userInfo.image && userInfo.image !== null" :src="userInfo.image" alt="Image" class="rounded-circle w-100 h-100">
                  <img v-if="!userInfo.image" src="../assets/img/profile-default.png" alt="Image" class="rounded-circle w-100 h-100">
                </div>
                <div class="profile-info">
                  <div class="profile-name mb-3">
                    <h3 class="fw-semibold mb-0">@{{userInfo.username}}</h3>
                  </div>
                  <label for="formFile2" class="form-label picture-label mb-0">Profil Fotoğrafını Değiştir</label>
                  <input class="form-control" type="file" ref="photoUpload" id="formFile2" @change="changeFile()" accept="image/png, image/jpeg" hidden>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 mt-3">
              <button @click="redirect('profile')" type="button" class="d-inline-block btn btn-sm btn-outline-light fw-semibold rounded-5 shadow-none ">İptal Et</button>
              <button type="button" @click="updateProfile()" class="d-inline-block btn btn-sm btn-primary fw-semibold rounded-5 shadow-none ms-2">Güncelle</button>
            </div>
          </div>
        </div>
        <form class="settings-form">
          <div class="mb-5 row align-items-center">
            <label for="username" class="col-sm-2 col-form-label fw-semibold">Kullanıcı Adı</label>
            <div class="col-sm-7">
              <input disabled type="text" class="form-control bg-transparent rounded-5 shadow-none" id="username" v-model="userInfo.username">
            </div>
          </div>
          <div class="mb-5 row align-items-center">
            <label for="name" class="col-sm-2 col-form-label fw-semibold">İsim</label>
            <div class="col-sm-7">
              <input type="text" class="form-control bg-transparent rounded-5 shadow-none" id="name" v-model="userInfo.name">
            </div>
          </div>
          <div class="mb-5 row align-items-center">
            <label for="lastname" class="col-sm-2 col-form-label fw-semibold">Soyisim</label>
            <div class="col-sm-7">
              <input type="text" class="form-control bg-transparent rounded-5 shadow-none" id="lastname" v-model="userInfo.surname">
            </div>
          </div>
          <div class="mb-5 row align-items-center">
            <label for="phone" class="col-sm-2 col-form-label fw-semibold">Cep Telefonu</label>
            <div class="col-sm-7">
              <input type="tel" class="form-control bg-transparent rounded-5 shadow-none" id="phone" v-model="userInfo.phone">
            </div>
          </div>
          <div class="mb-5 row align-items-center">
            <label for="email" class="col-sm-2 col-form-label fw-semibold">E-posta</label>
            <div class="col-sm-7">
              <input type="email" class="form-control bg-transparent rounded-5 shadow-none" id="email" v-model="userInfo.email">
            </div>
          </div>
        </form>
      </div>
    </section>
    <!-- APP-DOWNLOAD -->
    <section class="app-download">
      <div class="container-xxl">
        <div class="bg-gradient-2 rounded-20 position-relative overflow-hidden">
          <div class="row">
            <div class="app-info col-md-5 offset-md-1 col-10 position-relative text-center text-md-start">
              <h2 class="fw-bold">ClickMeLive Video <br> Alışveriş</h2>
              <p>Sosyal medya fenomenleri ve birbirinden renkli sunucuların anlatımıyla ürünleri telefonundan canlı izle. Arkadaşlarınla, sevdiklerinle paylaş ve video alışveriş keyfini birlikte yaşa.</p>
            </div>
            <div class="app-store-col col-md-5 col-11 mx-md-0 mx-auto">
              <div class="d-flex flex-column h-100">
                <div class="app-stores d-flex flex-nowrap mt-md-auto ms-auto me-auto me-md-0">
                  <a target="_blank" href="https://apps.apple.com/uz/app/clickmelive/id1571757578" class="d-inline-block app-store-link" style="margin-right: 8px;"><img src="../assets/img/icons/app-store.svg" alt="Image"></a>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.clickme.clickmelive&gl=TR" class="d-inline-block app-store-link"><img src="../assets/img/icons/playmarket.svg" alt="Image"></a>
                </div>
              </div>
            </div>
          </div>
          <div class="circle-left position-absolute top-50 translate-middle-y">
          </div>
          <div class="circle-right position-absolute">
          </div>
          <div class="circle-center position-absolute"></div>
        </div>
      </div>
    </section>
  </div>
</template>
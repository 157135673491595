<script>
export default {
    name: "TestimonialsComponent"
}
</script>
<template>
    <section class="testimonials">
    <div class="container-xxl">
      <div class="row align-items-center">
        <div class="col-md-7 order-2 order-md-1 text-md-start text-center">
          <h2 class="fw-bold">Fenomenler ve <br> birbirinden renkli <br> sunucular</h2>
          <p class="quote">Sosyal medya fenomenleri ve birbirinden renkli sunucuların anlatımıyla ürünleri telefonundan canlı izle.</p>
          <p class="quote">Arkadaşlarınla, sevdiklerinle paylaş ve video alışveriş keyfini birlikte yaşa.</p>
					<router-link to="/login" class="btn btn-lg btn-primary fw-bold rounded-12 shadow-none">Hemen Kaydol</router-link>
        </div>
        <div class="col-lg-4 offset-lg-1 col-md-5 col-11 order-1 order-md-2 mx-md-0 mx-auto">
          <img src="../assets/img/banners/banner-testimonil.png" alt="Image" class="w-100">
        </div>
      </div>
    </div>
  </section>
</template>
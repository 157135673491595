<script>
/* eslint-disable */
import SimpleTable from "@/components/SimpleTable";
import SimpleCard from "@/components/SimpleCard";
import SimpleCardDesktop from "@/components/SimpleCardDesktop";
import Warning from "@/components/Warning";
import axios from "axios";
import {mapMutations} from "vuex";
export default {
    name: "EventsPage",
  components:{
    SimpleCard,
		SimpleTable,
    SimpleCardDesktop,
		Warning
  },
    data(){
      return{
        events: [],
        currentPage: 1,
        eventType: 0,
        showLoadMore:true,
				simpleCardData: [],
				isAuthenticated: false,
      }
    },
    methods: {
      ...mapMutations([
        'SET_SPINNER_STATUS'
      ]),
      loadMore(){
        this.currentPage += 1
        this.getEvents(this.eventType);
      },
      getEvents(eventType){

        if(this.eventType != eventType){
          this.currentPage = 1;
        }

        if(eventType == 3){
          this.removeActiveVotedClass();
          return;
        }
        this.eventType = eventType;
        this.removeActiveClass();
        let navEvents = document.querySelectorAll("nav.events-nav")[0];
        if( eventType == 0) {
          navEvents.childNodes[3].childNodes[0].classList.add("active");
        } else if ( eventType == 1 ) {
          navEvents.childNodes[0].childNodes[0].classList.add("active");
        } else if ( eventType == 2 ) {
          navEvents.childNodes[2].childNodes[0].classList.add("active");
        } else if ( eventType == 3 ) {
          navEvents.childNodes[1].childNodes[0].classList.add("active");
        } else if ( eventType == 4 ) {
          navEvents.childNodes[3].childNodes[0].classList.add("active");
        } else {
          navEvents.childNodes[4].childNodes[0].classList.add("active");
        }
				this.handleFetchEvents(eventType);
        //this.fetchAllEvents(eventType);

      },
      removeActiveClass(){
        let nodeList = document.querySelectorAll("a.active");
        for(let i=0; i < nodeList.length; i++){
          nodeList[i].classList.remove("active");
        }
      },
      removeActiveVotedClass(){
        let navEvents = document.querySelectorAll("nav.events-nav")[0];
        navEvents.childNodes[2].childNodes[0].classList.remove("active");
      },
      getFilterEvents(eventType) {
        if ( eventType == 0) {
          return [0];
        } else if ( eventType == 1 ) {
          return [1];
        } else if ( eventType == 2 ) {
          return [2];
        } else if ( eventType == 3 ) {
          return [3];
        } else if ( eventType == 4 ) {
          return [4];
        } else{
          return [0,1]; //2 çıkarıldı?
        }
      },
			eventDetail(eventId){
				location.href = "/event-detail/"+eventId
			},
      async fetchSoldEventProducts(eventId) {
        try {
          this.SET_SPINNER_STATUS(true);
          const data = await axios.get(
              'https://prod-azure-superclick-api.clickmelive.com/api/v1/event/'+eventId+'/sold',
              {
                headers: {
                  'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
                  'Token': window.sessionStorage.getItem("Token")
                }
              }
          )
          this.SET_SPINNER_STATUS(false);
          return data.data;
        }catch (error) {
          this.SET_SPINNER_STATUS(false);
          console.log(error)
          return false;
        }
      },
      async fetchAllEvents(eventType) {
        try {
          this.SET_SPINNER_STATUS(true);
          const data = await axios.get(
              'https://prod-azure-superclick-api.clickmelive.com/api/v1/event?page='+this.currentPage+'&type='+eventType,
              {
                headers: {
                  'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
                }
              }
          )
					//event list
          this.SET_SPINNER_STATUS(false);

          if(this.currentPage == 1){
            this.events = [];
          }
          const eventData = data.data;
          const eventsArray = [];
          const simpleCardArray = [];
          const eventFilterArray = this.getFilterEvents(eventType);
          for (let index = 0; index < eventData.events.length; index++) {
            if(eventFilterArray.indexOf(eventData.events[index].type) > -1){
              const element = eventData.events[index];
              let eventImage = element.image.split("//");

              if (eventImage[1] === '') {
                element.image = "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg"
              }
              let endingDate = new Date(element.endingDate);
              element.endingDate = endingDate.toLocaleDateString("tr-TR");
              let startingDate = new Date(element.startingDate);
              element.startingDate = startingDate.toLocaleDateString("tr-TR");

              let lastVideoUploadDate = null;
              if(element.lastVideoUploadDate){
                lastVideoUploadDate = new Date(element.lastVideoUploadDate);
              }
              element.lastVideoUploadDate = lastVideoUploadDate ?  lastVideoUploadDate.toLocaleDateString("tr-TR") : "-";

              if(element.type == 2){
                let soldProducts = await this.fetchSoldEventProducts(element.eventId);
                if(soldProducts && soldProducts.length > 0){
                  element.isSold = true;
                }
              }

              eventsArray.push(element);
              if(eventType != 1 && eventType != 0){
                simpleCardArray.push(element);
              }
            } else if(eventType == 0) {
              const element = eventData.events[index];
              if(element.type == 1){
                simpleCardArray.push(element);
              }
            }
          }
          let eventStore = this.events;
          eventStore = eventStore.concat(eventsArray);
          this.events = eventStore;
					this.simpleCardData = simpleCardArray;

					let meta = eventData._metadata;
          if((meta.pageNumber * meta.pageSize) > meta.totalRecordCount){
            this.showLoadMore = false;
          } else {
            this.showLoadMore = true;
          }
        }catch (error) {
          console.log(error)
        }
      },
			handleFetchEvents(type){
				/*
				Etkinlik türleri
				0 => Gelecek
				1=> Açık etkinlik (ürünler satışa açık, video yüklenebilir)
				2=> Katılımı Sonlanan Etkinlikler (Ürünler stışa kapalı, video yüklenebilir)
				3=> Oylama (ürünler satışa kapalı. Video yüklenemez)
				4 => geçmiş etkinlik (ürünler satışa kapalı. Video yüklenemez)
				 */
				switch(type) {
					case 0:
						this.fetchAllEvents(0)
						break;
					case 1:
						this.fetchAllEvents(1)
						break;
					case 2:
						this.fetchAllEvents(2)
						break
					case 3:
						this.fetchAllEvents(3)
						break
					case 4:
						this.fetchAllEvents(4)
						break
					default:
					console.log("uygun tipte etkinlik tipi bulunmamaktadır.")
				}
			}
    },
    mounted() {
			this.getEvents(1);
      window.document.dispatchEvent(new Event("DOMContentLoaded", {
        bubbles: true,
        cancelable: true
      }));
    }
}
</script>
<template>
	<div>
		<!-- BG-GRAY-800 -->
		<section class="bg-gray-800 bg-gray-height d-flex align-items-center">
			<div  class="container-xxl ">
				<nav class="nav events-nav flex-nowrap">
          <li class="nav-item" @click="getEvents(1)"><a href="#" class="nav-link  fw-semibold py-0">Açık Etkinlikler</a></li>
          <!--<li class="nav-item" @click="getEvents(3)"><a href="#" class="nav-link fw-semibold py-0">Oylanan Etkinlikler</a></li>-->
          <li class="nav-item" @click="getEvents(2)"><a href="#" class="nav-link fw-semibold py-0">Katılımı Sonlanan Etkinlikler</a></li>
          <li class="nav-item" @click="getEvents(0)"><a href="#" class="nav-link fw-semibold py-0">Gelecek Etkinlikler</a></li>
          <!-- <li class="nav-item" @click="getEvents(4)"><a href="#" class="nav-link fw-semibold py-0">Geçmiş Etkinlikler</a></li>-->
          <!--<li class="nav-item" @click="getEvents()"><a href="#" class="nav-link active fw-semibold py-0">Tüm Etkinlikler</a></li>-->
        </nav>
			</div>
		</section>
		<!-- EVENTS-1 -->
		<section class="events">
			<div class="container-xxl">
				<!--desktop-->
				<!--
				<div class="event-cards-row d-none d-md-flex row row-cols-lg-4 row-cols-lg-2">
          <SimpleCardDesktop :card-data="simpleCardData"/>
        </div>-->
				<!--mobile-->
				<!--
				<div class="event-slider-row row d-md-none">
					<SimpleCard :card-data="simpleCardData"/>
				</div>-->
				<!--desktop table-->
				<div class="border-gray-600 rounded-10 d-none d-md-block mt-3">
					<div class="row">
						<div class="col-12">
							<SimpleTable :tableData="events" :eventType="eventType" />
						</div>
					</div>
				</div>
        <div class="mt-3 btn-load-more" v-if="showLoadMore">
          <div class="col-12 content-align-right" style="justify-content: center;margin-bottom: 10px">
            <button
                @click="loadMore"
                type="button"
                class="btn-table btn border-0 fw-semibold rounded-0 shadow-none p-0"
                style="color: yellow;">
              Daha Fazla Yükle
            </button>
          </div>
        </div>
				<!--mobile table-->
				<div class="row d-md-none mt-3">
					<div class="col-12">
						<div class="events-table-list">
							<div v-for="event in events" :key="event.eventId" class="events-mobile-table position-relative">
								<div class="d-flex align-items-center">
									<strong clas="fw-semibold">{{event.name}}</strong>&nbsp;&nbsp;
									<!--<span v-if="event.type == 0 || event.type == 2 || event.type == 4" class="badge bg-slate-blue fw-medium rounded-5 shadow-none">{{event.startingDate}}</span>-->
									<span v-if="event.type == 0 || event.type == 1 || event.type == 3" class="badge bg-slate-blue fw-medium rounded-5 shadow-none">Açık</span>
								</div>
								<div class="d-flex align-items-center">
                  <span class="fw-medium event-date">Video Yükleme Bitiş Tarihi:&nbsp;&nbsp;<span>{{event.lastVideoUploadDate}}</span></span>
									<span class="badge bg-yellow fw-medium d-inline-block position-relative rounded-0 ms-auto">Ücretsiz</span>
								</div>
								<div class="d-flex align-items-center">
                  <span class="fw-medium event-date">Bitiş Tarihi:&nbsp;&nbsp;<span>{{event.endingDate}}</span></span>
<!--									<span class="participation fw-semibold ms-auto">2/10</span>-->
								</div>
                <div class="d-flex align-items-center">
                  <span class="fw-medium total-prize">Toplam Ödül:&nbsp;&nbsp;<span class="fw-semibold color-violet">{{event.awardFee.toLocaleString("tr-TR")}} ₺</span></span>
                </div>
								<div class="d-flex align-items-center">
                  <span v-if="event.type==0" class="badge fw-medium rounded-5 bg-slate-blue bg-green" >Yakında!</span>
                  <button v-if="event.type==1" @click="eventDetail(event.eventId)" type="button" class="btn btn-blue-sm fw-medium shadow-none">Katıl</button>
                  <button v-if="event.type == 2 && event.isSold" @click="eventDetail(event.eventId)" type="button" class="btn btn-tomato-sm fw-medium shadow-none">Video Yükle</button>
                  <span v-if="event.type == 2 && !event.isSold" class="badge fw-medium rounded-5 bg-slate-blue bg-red">Tamamlandı</span>
                  <span v-if="event.type == 4" class="badge fw-medium rounded-5 bg-slate-blue bg-red">Tamamlandı</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- APP-DOWNLOAD -->
		<section class="app-download">
			<div class="container-xxl">
				<div class="bg-gradient-2 rounded-20 position-relative overflow-hidden">
					<div class="row">
						<div class="app-info col-md-5 offset-md-1 col-10 position-relative text-center text-md-start">
							<h2 class="fw-bold">ClickMeLive Video <br> Alışveriş</h2>
							<p>Sosyal medya fenomenleri ve birbirinden renkli sunucuların anlatımıyla ürünleri telefonundan canlı izle.
								Arkadaşlarınla, sevdiklerinle paylaş ve video alışveriş keyfini birlikte yaşa.</p>
						</div>
						<div class="app-store-col col-md-5 col-11 mx-md-0 mx-auto">
							<div class="d-flex flex-column h-100">
								<div class="app-stores d-flex flex-nowrap mt-md-auto ms-auto me-auto me-md-0">
									<a target="_blank" href="https://play.google.com/store/apps/details?id=com.clickme.clickmelive&gl=TR"><img src="../assets/img/icons/playmarket.svg" alt="Image" class="img-fluid"></a>
									<a target="_blank" href="https://apps.apple.com/uz/app/clickmelive/id1571757578"><img src="../assets/img/icons/app-store.svg" alt="Image" class="img-fluid"></a>
								</div>
							</div>
						</div>
					</div>
					<div class="circle-left position-absolute top-50 translate-middle-y">
					</div>
					<div class="circle-right position-absolute">
					</div>
					<div class="circle-center position-absolute"></div>
				</div>
			</div>
		</section>
	</div>
</template>
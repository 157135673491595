/* eslint-disable */
import { createStore } from "vuex";
import axios from "axios";

const baseUrl = "https://prod-azure-superclick-api.clickmelive.com/api/v1/";
const apiKey = "apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354";
export default createStore({
    state: {
        userCredentials: {
            "isRegister":false,
            "verificationCodeId":"",
            "username":"",
            "fullName":"",
            "email":"",
            "phone":"",
            "deviceInfo":{
                "deviceBrand":"Apple",
                "deviceModel":"Iphone 8 Plus Plus",
                "osVersion":"12.0.1",
                "appVersion":"1.0.0",
                "uniqueToken":"abc123",
                "firebaseToken":"def456"
            }
            },
        allEvents: [],
        banners: [],
        videos: [],
        shoppinCartData: [],
        priceInfo: {
            'araToplam': null,
            'indirimTutarı':null,
            'odenecekTutar':null
        },
        isRedirect: false,
        isAddBasket: false,
        isSpinnerShow: false,
        userInfo: {
            "userId":null,
            "image":"",
            "fullname":"",
            "username":""
        }
    },
    getters: {
        getUserCredentials: (state) => state.userCredentials,
        getAllEvents: (state) => state.allEvents,
        getBanners: (state) => state.banners,
        getPriceInfo: (state) => state.priceInfo,
        getVideos: (state) => state.videos,
        getShoppingCartData: (state) => state.shoppinCartData,
        getRedirectStatus: (state) => state.isRedirect,
        getIsAddBasket: (state) => state.isAddBasket,
        getSpinnerShow: (state) => state.isSpinnerShow,
        getUserInfo: (state) => state.userInfo,
    },
    actions: {
        async fetchAllEvents({ commit }) {
            try {
                const data = await axios.get(
                    baseUrl + 'event?page=1',
                    {
                        headers: {
                          'Authorization': apiKey
                        }
                    }
                )
                const eventData = data.data
                for (let index = 0; index < eventData.events.length; index++) {
                    const element = eventData.events[index];
                    let eventImage = element.image.split("//")
                    if (eventImage[1] === '') {
                        element.image = "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg"
                    }
                }
                commit('GET_ALL_EVENTS', eventData)
            }catch (error) {
                this.state.allEvents = null
                console.log(error)
            }
        },
        async fetchBanners({ commit }) {
            try {
                this.state.isSpinnerShow = true;
                const data = await axios.get(
                    baseUrl + 'banner?page=1',
                    {
                        headers: {
                          'Authorization': apiKey
                        }
                    }
                )
                this.state.isSpinnerShow = false;
                commit('GET_BANNERS', data.data)
            }catch (error) {
                this.state.banners = null
                this.state.isSpinnerShow = false;
                console.log(error)
            }
        },
        async fetchVideos({commit}){
            try {
                this.state.isSpinnerShow = true;
                const data = await axios.get(
                    baseUrl + 'event/1/video?page=1',
                    {
                        headers: {
                          'Authorization': apiKey
                        }
                    }
                )
                this.state.isSpinnerShow = false;
                commit('GET_VIDEOS', data.data)
            } catch (error) {
                this.state.isSpinnerShow = false;
                this.state.videos = null;
                console.log(error)
            }
        },
        async fetchCurrentBasket({commit}){
            try {
                if (this.state.shoppinCartData.length > 0){
                    do {
                        this.state.shoppinCartData.pop()
                    } while (this.state.shoppinCartData.length = 0);
                }
                this.state.isSpinnerShow = true;
                const data = await axios.get(
                  baseUrl + 'basket?stockControl=true',
                  {
                      headers: {
                          'Authorization': apiKey,
                          'Token': window.sessionStorage.getItem("Token")
                      }
                  }
                )
                this.state.isSpinnerShow = false;
                if(data.status == 200){
                    this.state.priceInfo = {
                        'araToplam': data.data.total.basketTotal ? Number(data.data.total.basketTotal).toLocaleString('tr-TR') : null,
                        'indirimTutarı':data.data.total.discountTotal ? Number(data.data.total.discountTotal).toLocaleString('tr-TR') : null,
                        'odenecekTutar':data.data.total.grandTotal ? Number(data.data.total.grandTotal).toLocaleString('tr-TR') : null
                    }
                }
                if (this.state.shoppinCartData.length === 0){
                    let result = data.data.seller;
                    for (let i = 0; i < result.length; i++) {
                        let elem = result[i];
                        for (let c = 0; c < elem.items.length; c++){
                            let product = elem.items[c]
                            let basketItem = {};
                            basketItem.basketItemId = product.basketItemId;
                            basketItem.image = product.product.image;
                            basketItem.name = product.product.name;
                            basketItem.discountedPrice = product.product.discountedPrice;
                            basketItem.price = product.product.price;
                            basketItem.scPrice = product.product.scPrice;
                            this.state.shoppinCartData.push(basketItem)
                        }
                    }
                }
            } catch (error) {
                this.state.isSpinnerShow = false;
                console.log(error)
            }
        }
    },
    mutations: {
        GET_ALL_EVENTS(state, allEvents){
            state.allEvents = allEvents
        },
        GET_BANNERS(state, banners){
            state.banners = banners
        },
        GET_VIDEOS(state, videos){
            state.videos = videos
        },
        ADD_CART_DATA: (state, data) => {
            if (!state.shoppinCartData.some(e => e.productId === data.productId)){
                state.shoppinCartData.push(data)
            }
        },
        REMOVE_CART_DATA: (state, data) => {
            for (let i = 0; i < state.shoppinCartData.length; i++){
                let item = state.shoppinCartData[i]
                if (item.basketItemId === data){
                    state.shoppinCartData.splice(i, 1);
                    return state.shoppinCartData
                }
            }
        },
        ADD_PRICE_INFO: (state, data) => {
            state.priceInfo = {
                'araToplam': data.data.total.basketTotal ? Number(data.data.total.basketTotal).toLocaleString('tr-TR') : null,
                'indirimTutarı':data.data.total.discountTotal ? Number(data.data.total.discountTotal).toLocaleString('tr-TR') : null,
                'odenecekTutar':data.data.total.grandTotal ? Number(data.data.total.grandTotal).toLocaleString('tr-TR') : null
            }
        },
        SHOW_REDIRECT: (state, data) => {
            state.isRedirect = data;
        },
        SHOW_ADD_BASKET: (state, data) => {
            state.isAddBasket = data;
        },
        SET_SPINNER_STATUS: (state, data) => {
            state.isSpinnerShow = data;
        },
        SET_USER_INFO: (state, data) => {
            state.userInfo = data;
        },
        SET_USER_CREDENTIAL: (state, data) => {
            state.userCredentials = {
                "isRegister":data.isRegister ? data.isRegister : false,
                "verificationCodeId":data.verificationCodeId ? data.verificationCodeId : "",
                "username":data.username ? data.username : "",
                "fullName":data.fullName ? data.fullName : "",
                "email":data.email ? data.email : "",
                "phone":data.phone ? data.phone : "",
                "deviceInfo":{
                    "deviceBrand":"Apple",
                    "deviceModel":"Iphone 8 Plus Plus",
                    "osVersion":"12.0.1",
                    "appVersion":"1.0.0",
                    "uniqueToken":"abc123",
                    "firebaseToken":"def456"
                }
            }
        }
    },
});
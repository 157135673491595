<script>
/* eslint-disable */
import VideoPlayerCustom from "@/components/VideoPlayerCustom";
import axios from "axios";
import {mapMutations, mapState} from "vuex";
import SimpleTableProfile from "@/components/SimpleTableProfile";
import video1 from "../assets/videos/1/1.mp4"

export default {
  name: "ProfilePage",
  components:{
    VideoPlayerCustom,
    SimpleTableProfile
  },
  data(){
    return{
			muted: false,
      userVideos: [],
      showLoadMoreVideos:false,
      showLoadMoreProducts:false,
      showLoadMoreEvents:false,
      productMap : {},
      userSoldProducts: [],
      userEvents: [],
      currentPageVideos: 1,
      currentPageProducts: 1,
      currentPageEvents: 1,
      section: 1,
      userName: "",
      video1: video1,
      userImage:""
    }
  },
  methods: {
    ...mapMutations([
      'SET_SPINNER_STATUS'
    ]),
		eventDetail(eventId){
			location.href = "/event-detail/"+eventId
		},
		muteVideos(){
			let currentVideo = document.querySelectorAll(".profile-videos-modal .slide-items.active video")
			for (let i = 0; i < currentVideo.length; i++) {
				if (currentVideo[i].muted === true){
					currentVideo[i].muted = false
					this.muted = false
				}else{
					currentVideo[i].muted = true
					this.muted = true
				}
			}
		},
		closeModal(){
			this.paused = false
			this.muted = false
			let allSlides = document.querySelectorAll(".profile-videos-modal .slide-items video")
			for (let i = 0; i < allSlides.length; i++) {
				allSlides[i].pause()
			}
			this.inspirationModal.hide()
		},
		goNextVideo(){
			this.muted = false
			let allSlides = document.querySelectorAll(".profile-videos-modal .slide-items")
			let allSlideVideos = document.querySelectorAll(".profile-videos-modal .slide-items video")
			let activeSlide = document.querySelector(".slide-items.active")
			let nextSlide = document.querySelector(".slide-items.next")
			let currentVideo = document.querySelector(".slide-items.active video")
			let nextVideo = document.querySelector(".slide-items.next video")
			for (let i = 0; i < allSlides.length; i++) {
				if (allSlides[i].classList.contains("prev")){
					allSlides[i].classList.remove("prev")
				}
			}
			for (let i = 0; i < allSlideVideos.length; i++) {
				allSlideVideos[i].muted = false
			}
			activeSlide.classList.remove("active")
			nextSlide.classList.remove("next")
			if (activeSlide.nextElementSibling){
				activeSlide.classList.add("prev")
				nextSlide.classList.add("active")
				nextSlide.nextElementSibling ? nextSlide.nextElementSibling.classList.add("next") : allSlides[0].classList.add("next")
				let itemTop;
				allSlides.forEach((item) => {
					itemTop = item.style.top
					itemTop = Number(itemTop.replace("vh", ""))
					item.style.top = (itemTop - 100) + "vh"
				})
				currentVideo.pause()
				nextVideo.play()
			}else{
				allSlides[0].classList.add("active")
				allSlides[1].classList.add("next")
				allSlides.forEach((item, index) => {
					item.classList.contains("prev") ? item.classList.remove("prev") : ""
					item.style.top = (index*100)+"vh"
				})
				currentVideo.pause();
				nextVideo.play()
			}
		},
		goPrevVideo(){
			this.muted = false
			let allSlides = document.querySelectorAll(".profile-videos-modal .slide-items")
			let allSlideVideos = document.querySelectorAll(".profile-videos-modal .slide-items video")
			let activeSlide = document.querySelector(".slide-items.active")
			let prevSlide = document.querySelector(".slide-items.prev")
			let currentVideo = document.querySelector(".slide-items.active video")
			let prevVideo = document.querySelector(".slide-items.prev video")
			if (activeSlide.previousElementSibling){
				for (let i = 0; i < allSlides.length; i++) {
					if (allSlides[i].classList.contains("next")){
						allSlides[i].classList.remove("next")
					}
				}
				activeSlide.classList.remove("active")
				prevSlide.classList.remove("prev")
				activeSlide.classList.add("next")
				prevSlide.classList.add("active")
				prevSlide.previousElementSibling ? prevSlide.previousElementSibling.classList.add("prev") : ""
				let itemTop;
				allSlides.forEach((item) => {
					itemTop = item.style.top
					itemTop = Number(itemTop.replace("vh", ""))
					item.style.top = (itemTop + 100) + "vh"
				})
				for (let i = 0; i < allSlideVideos.length; i++) {
					allSlideVideos[i].muted = false
				}
				currentVideo.pause()
				prevVideo ? prevVideo.play() : ""
			}
		},
		showModal(){
			this.inspirationModal = new window.bootstrap.Modal(document.getElementById("profileVideoModal"));
			this.inspirationModal.show();
			let slideItems = document.querySelectorAll(".profile-videos-modal .slide-items")
			slideItems[0].classList.add("active")
			slideItems[1].classList.add("next")
			let allSlides = document.querySelectorAll(".profile-videos-modal .slide-items")
			for (let i = 0; i < allSlides.length; i++) {
				allSlides[i].style.top = (i*100)+"vh"
			}
			let currentVideo = document.querySelector(".slide-items.active video")
			currentVideo.play()
		},
    async getUserProfile() {
      try {
        this.SET_SPINNER_STATUS(true);
        const data = await axios.get(
            'https://prod-azure-superclick-api.clickmelive.com/api/v1/me',
            {
              headers: {
                'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
                'Token': window.sessionStorage.getItem("Token")
              }
            }
        )
        this.userImage = data.data.image;
        this.userName = data.data.username;
        this.SET_SPINNER_STATUS(false);

      }catch (error) {
        console.log(error)
      }
    },
    setTab(type){
      if(type == "videos"){
        this.section = 1;
      } else if(type == "products"){
        this.section = 2;
      } else if(type == "events"){
        this.section = 3;
      }
    },
    async fetchUserVideos() {
      try {
        this.SET_SPINNER_STATUS(true);
        const data = await axios.get(
            'https://prod-azure-superclick-api.clickmelive.com/api/v1/me/video?page='+this.currentPageVideos,
            {
              headers: {
                'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
                'Token': window.sessionStorage.getItem("Token")
              }
            }
        )
        this.SET_SPINNER_STATUS(false);

        let meta = data.data._metadata;
        if((meta.pageNumber * meta.pageSize) > meta.totalRecordCount){
          this.showLoadMoreVideos = false;
        } else {
          this.showLoadMoreVideos = true;
        }

				
        const videos = data.data.videos;
        for(let i=0; i<videos.length;i++){
          let productDetail;
          if(Object.keys(this.productMap).indexOf(videos[i].productId) == -1){
            productDetail = await this.fetchProductDetail(videos[i].productId);
          } else {
            productDetail = this.productMap[videos[i].productId];
          }
          videos[i].productName = productDetail.name;
          videos[i].productDescription = productDetail.description;
          videos[i].productImage = productDetail.image;

          }

        let userVideos = this.userVideos;
        userVideos = userVideos.concat(videos);

        this.userVideos = userVideos;

      }catch (error) {
        console.log(error)
      }
    },
    async fetchProductDetail(productId) {
      try {
        this.SET_SPINNER_STATUS(true);
        const data = await axios.get(
            'https://prod-azure-superclick-api.clickmelive.com/api/v1/product/' + productId,
            {
              headers: {
                'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
                'Token': window.sessionStorage.getItem("Token")
              }
            }
        )
        this.SET_SPINNER_STATUS(false);

        return data.data;

      }catch (error) {
        console.log(error)
      }
    },
    async fetchAllEvents() {
      try {
        this.SET_SPINNER_STATUS(true);
        const data = await axios.get(
            'https://prod-azure-superclick-api.clickmelive.com/api/v1/me/event?page=1',
            {
              headers: {
                'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
                'Token': window.sessionStorage.getItem("Token")
              }
            }
        )
        this.editUserEvents(data.data.events);


        // let videos = [];
        // let productsMap = {};
        // let soldProductsMap = {};
        // let soldProductsArr = [];
        // let eventArr = [];
        // for(let i=0;i<data.data.events.length;i++) {
        //   let soldProducts = await this.getEventSoldProducts(data.data.events[i].eventId);
        //   if(soldProducts && soldProducts.length > 0){
        //     let productDetail = await this.getEventProducts(data.data.events[i].eventId);
        //     for (let z = 0; z < productDetail.products.length; z++) {
        //       if (soldProducts.indexOf(productDetail.products[z].productId) > -1 && !soldProductsMap[productDetail.products[z].productId]) {
        //         let sold = {
        //           "discountPrice": productDetail.products[z].discountPrice,
        //           "price": productDetail.products[z].price,
        //           "productImage": productDetail.products[z].image,
        //           "productName": productDetail.products[z].name,
        //           "productId": productDetail.products[z].productId
        //         }
        //         soldProductsMap[productDetail.products[z].productId] = sold;
        //         soldProductsArr.push(sold);
        //
        //         if (eventArr.indexOf(data.data.events[i].eventId) == -1) {
        //           eventArr.push(data.data.events[i]);
        //         }
        //       }
        //     }
        //   }
        //
        // }
        // for(let i=0;i<soldProductsArr.length;i++){
        //   this.userSoldProducts.push(soldProductsArr[i]);
        // }
        // this.editUserEvents(eventArr);


        this.SET_SPINNER_STATUS(false);

      }catch (error) {
        console.log(error)
      }
    },
    async fetchUserProducts() {
      try {
        this.SET_SPINNER_STATUS(true);
        const data = await axios.get(
            'https://prod-azure-superclick-api.clickmelive.com/api/v1/me/product?page='+this.currentPageProducts,
            {
              headers: {
                'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
                'Token': window.sessionStorage.getItem("Token")
              }
            }
        )
        let meta = data.data._metadata;
        if((meta.pageNumber * meta.pageSize) > meta.totalRecordCount){
          this.showLoadMoreProducts = false;
        } else {
          this.showLoadMoreProducts = true;
        }

        let userSoldProducts = this.userSoldProducts;
        userSoldProducts = userSoldProducts.concat(data.data.products);
        this.userSoldProducts = userSoldProducts;

        // let videos = [];
        // let productsMap = {};
        // let soldProductsMap = {};
        // let soldProductsArr = [];
        // let eventArr = [];
        // for(let i=0;i<data.data.events.length;i++) {
        //   let soldProducts = await this.getEventSoldProducts(data.data.events[i].eventId);
        //   if(soldProducts && soldProducts.length > 0){
        //     let productDetail = await this.getEventProducts(data.data.events[i].eventId);
        //     for (let z = 0; z < productDetail.products.length; z++) {
        //       if (soldProducts.indexOf(productDetail.products[z].productId) > -1 && !soldProductsMap[productDetail.products[z].productId]) {
        //         let sold = {
        //           "discountPrice": productDetail.products[z].discountPrice,
        //           "price": productDetail.products[z].price,
        //           "productImage": productDetail.products[z].image,
        //           "productName": productDetail.products[z].name,
        //           "productId": productDetail.products[z].productId
        //         }
        //         soldProductsMap[productDetail.products[z].productId] = sold;
        //         soldProductsArr.push(sold);
        //
        //         if (eventArr.indexOf(data.data.events[i].eventId) == -1) {
        //           eventArr.push(data.data.events[i]);
        //         }
        //       }
        //     }
        //   }
        //
        // }
        // for(let i=0;i<soldProductsArr.length;i++){
        //   this.userSoldProducts.push(soldProductsArr[i]);
        // }
        // this.editUserEvents(eventArr);


        this.SET_SPINNER_STATUS(false);

      }catch (error) {
        console.log(error)
      }
    },
    editUserEvents(events) {
      const eventsArray = [];
      const eventIds = [];
      for (let index = 0; index < events.length; index++) {
        if (eventIds.indexOf(events[index].eventId) == -1) {
          const element = events[index];
          let eventImage = element.image.split("//");

          if (eventImage[1] === '') {
            element.image = "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg"
          }
          let endingDate = new Date(element.endingDate);
          element.endingDate = endingDate.toLocaleDateString("tr-TR");
          let startingDate = new Date(element.startingDate);
          element.startingDate = startingDate.toLocaleDateString("tr-TR");

          let lastVideoUploadDate = null;
          if(element.lastVideoUploadDate){
            lastVideoUploadDate = new Date(element.lastVideoUploadDate);
          }
          element.lastVideoUploadDate = lastVideoUploadDate ?  lastVideoUploadDate.toLocaleDateString("tr-TR") : "-";

          eventIds.push(element.eventId);
          eventsArray.push(element);
        }
      }
      this.userEvents = eventsArray;

    },
    async getEventVideo(eventId) {
      try {
        this.SET_SPINNER_STATUS(true);
        const data = await axios.get(
            'https://prod-azure-superclick-api.clickmelive.com/api/v1/event/'+eventId + '/video?page=1',
            {
              headers: {
                'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
                'Token': window.sessionStorage.getItem("Token")
              }
            }
        )
        this.SET_SPINNER_STATUS(false);
        return data.data;

      }catch (error) {
        console.log(error)
      }
    },
    async getEventProducts(eventId) {
      try {
        this.SET_SPINNER_STATUS(true);
        const data = await axios.get(
            'https://prod-azure-superclick-api.clickmelive.com/api/v1/event/'+eventId + '/product?page=1',
            {
              headers: {
                'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
                'Token': window.sessionStorage.getItem("Token")
              }
            }
        )
        this.SET_SPINNER_STATUS(false);
        return data.data;

      }catch (error) {
        console.log(error)
      }
    },
    async fetchEventSoldProducts() {
      try {
        this.SET_SPINNER_STATUS(true);
        for(let i=0;i<this.userEvents.length;i++){
          let isSold = await this.getEventSoldProducts(this.userEvents[i].eventId);
        }
        this.SET_SPINNER_STATUS(false);
        return data.data;

      }catch (error) {
        console.log(error)
      }
    },
    async getEventSoldProducts(eventId) {
      try {
        this.SET_SPINNER_STATUS(true);
        const data = await axios.get(
            'https://prod-azure-superclick-api.clickmelive.com/api/v1/event/'+eventId + '/sold',
            {
              headers: {
                'Authorization': 'apiKey f3594b6a38d9292c42fcbaa072b41f3194bcc21d19ad9710c844e3f71c8d1354',
                'Token': window.sessionStorage.getItem("Token")
              }
            }
        )
        this.SET_SPINNER_STATUS(false);
        return data.data;

      }catch (error) {
        console.log(error)
      }
    },
    loadMoreVideos(){
      this.currentPageVideos += 1
      this.fetchUserVideos();
    },
    loadMoreProducts(){
      this.currentPageProducts += 1
      this.fetchUserProducts();
    }
  },
  computed:{
    getUserInfoStore(){
      return this.$store.getters.getUserInfo
    }
  },
  mounted() {
    this.getUserProfile();
    this.fetchUserVideos();
    this.fetchAllEvents();
    this.fetchUserProducts();
    window.document.dispatchEvent(new Event("DOMContentLoaded", {
      bubbles: true,
      cancelable: true
    }));
  }
}
</script>
<template>
  <div>
    <section class="bg-gray-800 bg-gray-height d-flex align-items-center">
      <div class="container-xxl">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item fw-semibold active" aria-current="page">Profil</li>
          </ol>
        </nav>
      </div>
    </section>
    <!-- PROFILE -->
    <section class="profile">
      <div class="container-xxl">
        <div class="profile-block d-flex flex-wrap align-items-center">
          <div class="profile-image">
            <img v-if="userImage" :src="userImage" alt="Image" class="rounded-circle w-100 h-100">
            <img v-if="!userImage" src="../assets/img/profile-default.png" alt="Image" class="rounded-circle w-100 h-100">
          </div>
          <div class="profile-info">
            <div class="profile-name d-flex align-items-center">
              <h3 class="fw-semibold mb-0 mt-3">@{{userName}}</h3>
              <button type="button" class="btn btn-edit border-0 rounded-0 p-0 shadow-none">
                <router-link to="/edit-profile" class="d-block fw-semibold text-decoration-none" id="mobileEvent"><img src="../assets/img/icons/edit.svg" alt="Edit"></router-link>
              </button>
            </div>
            <ul class="profile-info-list list-inline mb-0">
              <li class="list-inline-item">
                <a href="#" class="d-block text-decoration-none fw-semibold">{{userVideos.length}} <span class="fw-normal">video</span></a>
              </li>
              <li class="list-inline-item">
                <a href="#" class="d-block text-decoration-none fw-semibold">{{userSoldProducts.length}} <span class="fw-normal">ürün</span></a>
              </li>
              <li class="list-inline-item">
                <a href="#" class="d-block text-decoration-none fw-semibold">{{userEvents.length}} <span class="fw-normal">etkinlik</span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- USER-CONTENT -->
    <section class="user-content" v-if="section==1">
      <div class="container-xxl">
        <div class="row">
          <div class="col-12">
            <ul class="user-content-nav nav justify-content-center">
              <li class="nav-item"><a style="cursor:pointer" @click="setTab('videos')" class="nav-link active fw-medium">Videolar</a></li>
              <li class="nav-item"><a style="cursor:pointer" @click="setTab('products')" class="nav-link fw-medium">Ürünler</a></li>
              <li class="nav-item"><a style="cursor:pointer" @click="setTab('events')" class="nav-link fw-medium">Etkinlikler</a></li>
            </ul>
          </div>
        </div>
				<!--video-->
				<section class="influential-content">
					<div class="container-xxl">
						<!--desktop-->
						<div class="row row-cols-lg-4 d-md-flex d-none">
							<div v-for="(video, index) in userVideos" :key="video.videoId" class="col">
								<div class="video position-relative event-videos">
									<img :src="video.image" alt="Image" class="w-100">
									<i @click="showModal()" :data-index="index" class="icon-play-button profile-play-button position-absolute top-50 start-50 translate-middle btn btn-play" role="button"></i>
									<!--<span @click="playorPauseVideo()" v-if="paused" class="btn-playorpause"></span>-->
								</div>
								<!--<VideoPlayerCustom :video-url="video.url" :poster="video.image" class="w-100 video-popup custom" />-->
							</div>
						</div>
            <div class="mt-3 btn-load-more" v-if="showLoadMoreVideos">
              <div class="col-12 content-align-right" style="justify-content: center;margin-bottom: 10px">
                <button
                    @click="loadMoreVideos"
                    type="button"
                    class="btn-table btn border-0 fw-semibold rounded-0 shadow-none p-0"
                    style="color: yellow;">
                  Daha Fazla Yükle
                </button>
              </div>
            </div>
						<!--mobile-->
						<div class="row d-md-none">
							<div class="col-10 mx-auto">
								<div class="event-video-slider swiper">
									<div class="swiper-wrapper">
										<div v-for="(video, index) in userVideos" :key="video.videoId" class="swiper-slide h-auto">
											<div class="video position-relative event-videos">
												<img :src="video.image" alt="Image" class="w-100">
												<i @click="showModal()" :data-index="index" class="icon-play-button profile-play-button position-absolute top-50 start-50 translate-middle btn btn-play" role="button"></i>
											</div>
											<!--<VideoPlayerCustom :video-url="video.url" :poster="video.image" class="w-100 video-popup custom" />-->
										</div>
									</div>
									<div class="swiper-pagination position-relative"></div>
								</div>
							</div>
						</div>
					</div>
				</section>
      </div>
    </section>

    <!-- ONLINE-SHOP -->
    <section class="online-shop pt-0" v-if="section==2">
      <div class="container-xxl">
        <div class="row">
          <div class="col-12">
            <ul class="user-content-nav nav justify-content-center">
              <li class="nav-item"><a style="cursor:pointer" @click="setTab('videos')" class="nav-link fw-medium">Videolar</a></li>
              <li class="nav-item"><a style="cursor:pointer" @click="setTab('products')" class="nav-link active fw-medium">Ürünler</a></li>
              <li class="nav-item"><a style="cursor:pointer" @click="setTab('events')" class="nav-link fw-medium">Etkinlikler</a></li>
            </ul>
          </div>
        </div>
        <div class="product-row row row-cols-lg-3 row-cols-2 gx-lg-4 gx-3">
          <template v-for="product in userSoldProducts">
            <div class="col">
              <div class="product-item">
                <div class="product-image">
                  <img :src="product.image" alt="Image" class="w-100 rounded-10">
                </div>
                <div class="product-info">
                  <h4 class="fw-normal">
                    <a href="#" class="text-decoration-none">{{product.name}}</a>
                  </h4>
                  <template v-if="product.scPrice">
                    <span class="new-price d-inline-block fw-semibold">{{ product.scPrice.toLocaleString("tr-TR") }}₺</span>
                    <del class="old-price d-inline-block fw-light">{{ product.price.toLocaleString("tr-TR") }}₺</del>
                  </template>
                  <template v-if="!product.scPrice">
                    <span class="new-price d-inline-block fw-semibold">{{ product.price.toLocaleString("tr-TR") }}₺</span>
                  </template>

                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="mt-3 btn-load-more" v-if="showLoadMoreProducts">
          <div class="col-12 content-align-right" style="justify-content: center;margin-bottom: 10px">
            <button
                @click="loadMoreProducts"
                type="button"
                class="btn-table btn border-0 fw-semibold rounded-0 shadow-none p-0"
                style="color: yellow;">
              Daha Fazla Yükle
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- EVENTS-1 -->
    <section class="events"  v-if="section==3">
      <div class="container-xxl">
        <div class="row">
          <div class="col-12">
            <ul class="user-content-nav nav justify-content-center">
              <li class="nav-item"><a style="cursor:pointer" @click="setTab('videos')" class="nav-link fw-medium">Videolar</a></li>
              <li class="nav-item"><a style="cursor:pointer" @click="setTab('products')" class="nav-link fw-medium">Ürünler</a></li>
              <li class="nav-item"><a style="cursor:pointer" @click="setTab('events')" class="nav-link active fw-medium">Etkinlikler</a></li>
            </ul>
          </div>
        </div>
        <div class="border-gray-600 rounded-10 d-none d-md-block">
          <div class="row">
            <div class="col-12">
              <div class="col-12">
                <SimpleTableProfile :tableData="userEvents"  />
              </div>
            </div>
          </div>
        </div>
        <div class="row d-md-none">
          <div class="col-12">
            <div class="events-table-list">
              <template v-for="event in userEvents">
                <div class="events-mobile-table position-relative">
                  <div class="d-flex align-items-center">
                    <strong clas="fw-semibold">{{event.name}}</strong>&nbsp;&nbsp;
										<!--
                    <span v-if="event.type == 1" class="badge bg-slate-blue fw-medium rounded-5 shadow-none">Açık</span>
										<span v-if="event.type==4" class="badge fw-medium rounded-5 bg-slate-blue bg-red">Tamamlandı</span>-->
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="fw-medium event-date">Video Yükleme Bitiş Tarihi:&nbsp;&nbsp;<span>{{event.lastVideoUploadDate}}</span></span>
                    <span class="badge bg-yellow fw-medium d-inline-block position-relative rounded-0 ms-auto">Ücretsiz</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="fw-medium event-date">Bitiş Tarihi:&nbsp;&nbsp;<span>{{event.endingDate}}</span></span>
                    <!--									<span class="participation fw-semibold ms-auto">2/10</span>-->
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="fw-medium total-prize">Toplam Ödül:&nbsp;&nbsp;<span class="fw-semibold color-violet">{{event.awardFee.toLocaleString("tr-TR")}} ₺</span></span>
                    <!-- <span class="participation fw-semibold ms-auto">2/10</span> -->
                  </div>
                  <div class="d-flex align-items-center">
                    <span v-if="event.type==0" class="badge fw-medium rounded-5 bg-slate-blue bg-green" >Yakında!</span>
                    <button v-if="event.type==1 || event.type==2" @click="eventDetail(event.eventId)" type="button" class="btn btn-tomato-sm fw-medium rounded-5 shadow-none p-1">Video Yükle</button>
										<span v-if="event.type == 3" class="badge fw-medium rounded-5 bg-slate-blue bg-red">Oylamada</span>
                    <span v-if="event.type==4" class="badge fw-medium rounded-5 bg-slate-blue bg-red">Tamamlandı</span>
									</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- APP-DOWNLOAD -->
    <section class="app-download">
      <div class="container-xxl">
        <div class="bg-gradient-2 rounded-20 position-relative overflow-hidden">
          <div class="row">
            <div class="app-info col-md-5 offset-md-1 col-10 position-relative text-center text-md-start">
              <h2 class="fw-bold">ClickMeLive Video <br> Alışveriş</h2>
              <p>Sosyal medya fenomenleri ve birbirinden renkli sunucuların anlatımıyla ürünleri telefonundan canlı izle. Arkadaşlarınla, sevdiklerinle paylaş ve video alışveriş keyfini birlikte yaşa.</p>
            </div>
            <div class="app-store-col col-md-5 col-11 mx-md-0 mx-auto">
              <div class="d-flex flex-column h-100">
                <div class="app-stores d-flex flex-nowrap mt-md-auto ms-auto me-auto me-md-0">
                  <a target="_blank" href="https://apps.apple.com/uz/app/clickmelive/id1571757578" class="d-inline-block app-store-link" style="margin-right: 8px;"><img src="../assets/img/icons/app-store.svg" alt="Image"></a>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.clickme.clickmelive&gl=TR" class="d-inline-block app-store-link"><img src="../assets/img/icons/playmarket.svg" alt="Image"></a>
                </div>
              </div>
            </div>
          </div>
          <div class="circle-left position-absolute top-50 translate-middle-y">
          </div>
          <div class="circle-right position-absolute">
          </div>
          <div class="circle-center position-absolute"></div>
        </div>
      </div>
    </section>
  </div>
	
	<!-- VIDEO-MODAL -->
	<div class="video-modal modal fade profile-videos-modal" id="profileVideoModal" tabindex="-1" aria-labelledby="videoModalLabel" aria-hidden="true" data-keyboard="false" data-backdrop="static">
		<div class="modal-dialog modal-dialog-centered modal-lg mx-auto">
			<div class="modal-content bg-transparent">
				<div class="modal-body">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12 position-relative">
								<div>
									<div class="slide-wrapper">
										<div v-for="video in userVideos" :key="video.videoId" class="slide-items">
											<div class="video-block position-relative">
												<VideoPlayerCustom :video-url="video.url" class="w-100 video-popup custom" />
											</div>
											<span @click="playorPauseVideo()" v-if="paused" class="btn-playorpause fixed"></span>
										</div>
									</div>
								</div>
								<span @click="playorPauseVideo" class="video-cover"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<span @click="closeModal()" class="btn-modal-close">
				<svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="31.5" cy="31.5" r="31.5" fill="#C4C4C4" fill-opacity="0.7"/>
					<path d="M33.3768 31.9943L43.7153 21.6518C43.8977 21.4686 44 21.2207 43.9998 20.9623C43.9996 20.7038 43.897 20.456 43.7144 20.2731C43.3488 19.9096 42.7049 19.9077 42.3357 20.2749L32 30.6174L21.6606 20.2722C21.2932 19.9096 20.6494 19.9114 20.2838 20.274C20.1931 20.3643 20.1213 20.4718 20.0725 20.5902C20.0238 20.7085 19.9992 20.8354 20 20.9634C20 21.2241 20.1011 21.4682 20.2838 21.6491L30.6223 31.9933L20.2848 42.3385C20.1023 42.522 20.0002 42.7703 20.0007 43.0289C20.0012 43.2876 20.1044 43.5355 20.2875 43.7182C20.4648 43.8935 20.7155 43.9945 20.9745 43.9945H20.98C21.24 43.9936 21.4907 43.8917 21.6643 43.7145L32 33.3721L42.3394 43.7173C42.5222 43.899 42.7665 44 43.0255 44C43.1536 44.0004 43.2804 43.9754 43.3988 43.9266C43.5172 43.8778 43.6248 43.8061 43.7154 43.7155C43.8059 43.625 43.8777 43.5175 43.9266 43.3992C43.9754 43.2809 44.0004 43.1541 44 43.0261C44 42.7663 43.899 42.5212 43.7153 42.3404L33.3768 31.9943Z" fill="black"/>
				</svg>
			</span>
		<span @click="muteVideos()" class="btn-mute-modal">
				<svg v-if="!muted" width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="31.5" cy="31.5" r="31.5" fill="#C4C4C4" fill-opacity="0.7"/>
					<path d="M40.8077 24.2891C40.7148 24.1962 40.6045 24.1225 40.4831 24.0723C40.3617 24.022 40.2316 23.9961 40.1002 23.9961C39.9688 23.9961 39.8387 24.022 39.7173 24.0723C39.5959 24.1225 39.4856 24.1962 39.3927 24.2891C39.2998 24.3821 39.2261 24.4924 39.1758 24.6138C39.1255 24.7351 39.0996 24.8653 39.0996 24.9966C39.0996 25.128 39.1255 25.2582 39.1758 25.3795C39.2261 25.5009 39.2998 25.6112 39.3927 25.7041C41.0596 27.3751 41.9958 29.6389 41.9958 31.9991C41.9958 34.3594 41.0596 36.6232 39.3927 38.2941C39.205 38.4818 39.0996 38.7363 39.0996 39.0016C39.0996 39.267 39.205 39.5215 39.3927 39.7091C39.5803 39.8968 39.8348 40.0022 40.1002 40.0022C40.3655 40.0022 40.62 39.8968 40.8077 39.7091C42.8494 37.6627 43.9961 34.89 43.9961 31.9991C43.9961 29.1083 42.8494 26.3356 40.8077 24.2891Z" fill="black"/>
					<path d="M38.1 27.2909C38.0071 27.1977 37.8967 27.1237 37.7752 27.0731C37.6537 27.0225 37.5234 26.9963 37.3918 26.9961C37.2602 26.9959 37.1298 27.0216 37.0081 27.0717C36.8864 27.1219 36.7758 27.1955 36.6825 27.2884C36.5893 27.3813 36.5153 27.4917 36.4647 27.6132C36.4141 27.7347 36.3879 27.865 36.3877 27.9967C36.3875 28.1283 36.4132 28.2587 36.4633 28.3804C36.5135 28.5021 36.5871 28.6127 36.68 28.7059C37.5528 29.58 38.043 30.7647 38.043 31.9999C38.043 33.2351 37.5528 34.4198 36.68 35.2939C36.5871 35.3872 36.5135 35.4978 36.4633 35.6195C36.4132 35.7412 36.3875 35.8716 36.3877 36.0032C36.3879 36.1348 36.4141 36.2651 36.4647 36.3866C36.5153 36.5081 36.5893 36.6185 36.6825 36.7114C36.8708 36.8991 37.126 37.0042 37.3918 37.0037C37.5234 37.0035 37.6537 36.9774 37.7752 36.9268C37.8967 36.8762 38.0071 36.8022 38.1 36.7089C39.3473 35.4592 40.0479 33.7656 40.0479 31.9999C40.0479 30.2342 39.3473 28.5407 38.1 27.2909Z" fill="black"/>
					<path d="M33.82 20.2001C30.7793 20.7714 28.0747 22.49 26.266 25.0001H25C23.6746 25.0022 22.404 25.5297 21.4668 26.4669C20.5296 27.4041 20.0021 28.6747 20 30.0001V34.0001C20.0021 35.3255 20.5296 36.5961 21.4668 37.5333C22.404 38.4705 23.6746 38.998 25 39.0001H26.266C28.075 41.5099 30.7795 43.2284 33.82 43.8001C33.8796 43.8118 33.9403 43.8175 34.001 43.8171C34.2662 43.8171 34.5206 43.7118 34.7081 43.5242C34.8956 43.3367 35.001 43.0823 35.001 42.8171V21.1861C35.0014 21.0392 34.9694 20.8941 34.9073 20.761C34.8453 20.6279 34.7546 20.5101 34.6419 20.416C34.5292 20.3218 34.3971 20.2537 34.255 20.2164C34.113 20.1791 33.9644 20.1735 33.82 20.2001ZM33 41.5351C30.7944 40.832 28.8979 39.3914 27.629 37.4551C27.5383 37.3156 27.4143 37.201 27.2682 37.1215C27.122 37.0421 26.9584 37.0003 26.792 37.0001H25C24.2044 37.0001 23.4413 36.684 22.8787 36.1214C22.3161 35.5588 22 34.7958 22 34.0001V30.0001C22 29.2045 22.3161 28.4414 22.8787 27.8788C23.4413 27.3162 24.2044 27.0001 25 27.0001H26.8C26.9662 27.0001 27.1297 26.9587 27.2758 26.8796C27.422 26.8005 27.5461 26.6862 27.637 26.5471C28.9033 24.6113 30.7969 23.1701 33 22.4651V41.5351Z" fill="black"/>
				</svg>
				<svg v-if="muted" width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="31.5" cy="31.5" r="31.5" fill="#C4C4C4" fill-opacity="0.7"/>
					<path d="M40.8077 24.2901C40.7148 24.1972 40.6045 24.1235 40.4831 24.0732C40.3617 24.023 40.2316 23.9971 40.1002 23.9971C39.9688 23.9971 39.8387 24.023 39.7173 24.0732C39.5959 24.1235 39.4856 24.1972 39.3927 24.2901C39.2998 24.383 39.2261 24.4933 39.1758 24.6147C39.1255 24.7361 39.0996 24.8662 39.0996 24.9976C39.0996 25.129 39.1255 25.2591 39.1758 25.3805C39.2261 25.5019 39.2998 25.6122 39.3927 25.7051C41.0596 27.376 41.9958 29.6399 41.9958 32.0001C41.9958 34.3604 41.0596 36.6242 39.3927 38.2951C39.205 38.4828 39.0996 38.7373 39.0996 39.0026C39.0996 39.268 39.205 39.5225 39.3927 39.7101C39.5803 39.8978 39.8348 40.0032 40.1002 40.0032C40.3655 40.0032 40.62 39.8978 40.8077 39.7101C42.8494 37.6637 43.9961 34.8909 43.9961 32.0001C43.9961 29.1093 42.8494 26.3366 40.8077 24.2901Z" fill="black"/>
					<path d="M38.1 27.2909C38.0071 27.1977 37.8967 27.1237 37.7752 27.0731C37.6537 27.0225 37.5234 26.9963 37.3918 26.9961C37.2602 26.9959 37.1298 27.0216 37.0081 27.0717C36.8864 27.1219 36.7758 27.1955 36.6825 27.2884C36.5893 27.3813 36.5153 27.4917 36.4647 27.6132C36.4141 27.7347 36.3879 27.865 36.3877 27.9967C36.3875 28.1283 36.4132 28.2587 36.4633 28.3804C36.5135 28.5021 36.5871 28.6127 36.68 28.7059C37.5528 29.58 38.043 30.7647 38.043 31.9999C38.043 33.2351 37.5528 34.4198 36.68 35.2939C36.5871 35.3872 36.5135 35.4978 36.4633 35.6195C36.4132 35.7412 36.3875 35.8716 36.3877 36.0032C36.3879 36.1348 36.4141 36.2651 36.4647 36.3866C36.5153 36.5081 36.5893 36.6185 36.6825 36.7114C36.8708 36.8991 37.126 37.0042 37.3918 37.0037C37.5234 37.0035 37.6537 36.9774 37.7752 36.9268C37.8967 36.8762 38.0071 36.8022 38.1 36.7089C39.3473 35.4592 40.0479 33.7656 40.0479 31.9999C40.0479 30.2342 39.3473 28.5407 38.1 27.2909Z" fill="black"/>
					<path d="M33.82 20.2001C30.7793 20.7714 28.0747 22.49 26.266 25.0001H25C23.6746 25.0022 22.404 25.5297 21.4668 26.4669C20.5296 27.4041 20.0021 28.6747 20 30.0001V34.0001C20.0021 35.3255 20.5296 36.5961 21.4668 37.5333C22.404 38.4705 23.6746 38.998 25 39.0001H26.266C28.075 41.5099 30.7795 43.2284 33.82 43.8001C33.8796 43.8118 33.9403 43.8175 34.001 43.8171C34.2662 43.8171 34.5206 43.7118 34.7081 43.5242C34.8956 43.3367 35.001 43.0823 35.001 42.8171V21.1861C35.0014 21.0392 34.9694 20.8941 34.9073 20.761C34.8453 20.6279 34.7546 20.5101 34.6419 20.416C34.5292 20.3218 34.3971 20.2537 34.255 20.2164C34.113 20.1791 33.9644 20.1735 33.82 20.2001ZM33 41.5351C30.7944 40.832 28.8979 39.3914 27.629 37.4551C27.5383 37.3156 27.4143 37.201 27.2682 37.1215C27.122 37.0421 26.9584 37.0003 26.792 37.0001H25C24.2044 37.0001 23.4413 36.684 22.8787 36.1214C22.3161 35.5588 22 34.7958 22 34.0001V30.0001C22 29.2045 22.3161 28.4414 22.8787 27.8788C23.4413 27.3162 24.2044 27.0001 25 27.0001H26.8C26.9662 27.0001 27.1297 26.9587 27.2758 26.8796C27.422 26.8005 27.5461 26.6862 27.637 26.5471C28.9033 24.6113 30.7969 23.1701 33 22.4651V41.5351Z" fill="black"/>
					<line x1="19" y1="43.5858" x2="43.5858" y2="19" stroke="black" stroke-width="2" stroke-linecap="round"/>
				</svg>
		</span>
		<span @click="goNextVideo()" class="btn-modal-next">
				<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0.292786 0.305287C0.105314 0.492815 -5.79302e-07 0.747124 -5.67711e-07 1.01229C-5.5612e-07 1.27745 0.105315 1.53176 0.292786 1.71929L4.87879 6.30529C5.06128 6.49542 5.16318 6.74875 5.16318 7.01229C5.16318 7.27583 5.06128 7.52916 4.87879 7.71929L0.292786 12.3053C0.110628 12.4939 0.00983367 12.7465 0.0121121 13.0087C0.0143905 13.2709 0.119559 13.5217 0.304967 13.7071C0.490376 13.8925 0.741188 13.9977 1.00339 14C1.26558 14.0022 1.51818 13.9014 1.70679 13.7193L6.29279 9.13429C6.57142 8.8557 6.79244 8.52496 6.94324 8.16096C7.09403 7.79695 7.17165 7.4068 7.17165 7.01279C7.17165 6.61878 7.09403 6.22863 6.94324 5.86462C6.79244 5.50061 6.57142 5.16987 6.29279 4.89129L1.70679 0.305287C1.51926 0.117816 1.26495 0.0125017 0.999785 0.0125017C0.734621 0.0125017 0.480313 0.117816 0.292786 0.305287Z" fill="white"/>
				</svg>
			</span>
		<span @click="goPrevVideo()" class="btn-modal-prev">
				<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0.292786 0.305287C0.105314 0.492815 -5.79302e-07 0.747124 -5.67711e-07 1.01229C-5.5612e-07 1.27745 0.105315 1.53176 0.292786 1.71929L4.87879 6.30529C5.06128 6.49542 5.16318 6.74875 5.16318 7.01229C5.16318 7.27583 5.06128 7.52916 4.87879 7.71929L0.292786 12.3053C0.110628 12.4939 0.00983367 12.7465 0.0121121 13.0087C0.0143905 13.2709 0.119559 13.5217 0.304967 13.7071C0.490376 13.8925 0.741188 13.9977 1.00339 14C1.26558 14.0022 1.51818 13.9014 1.70679 13.7193L6.29279 9.13429C6.57142 8.8557 6.79244 8.52496 6.94324 8.16096C7.09403 7.79695 7.17165 7.4068 7.17165 7.01279C7.17165 6.61878 7.09403 6.22863 6.94324 5.86462C6.79244 5.50061 6.57142 5.16987 6.29279 4.89129L1.70679 0.305287C1.51926 0.117816 1.26495 0.0125017 0.999785 0.0125017C0.734621 0.0125017 0.480313 0.117816 0.292786 0.305287Z" fill="white"/>
				</svg>
			</span>
	</div>
</template>

<style scoped>
.video-image-boxes .image-box {display: flex;justify-content: center;align-items: center;}

.video-image-boxes .image-box img {max-width: 100%;height: auto !important;}
.video-modal .modal-body {
	padding: 0;
}
.video-modal .modal-dialog {
	margin: 10px 0;
}

.swiper-video .video-block,
.swiper-video .video-js {
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 100vh;
}

.swiper-video video {
	width: 100%;
	height: auto;
	max-height: 100%;
}

span.btn-modal-close {
	position: fixed;
	top: 8px;
	right: 8px;
	width: 45px;
	height: 45px;
	overflow: hidden;
	z-index: 1;
	cursor: pointer;
}

span.btn-modal-close svg {
	width: 100%;
	height: 100%;
	cursor: pointer;
}
.video-modal {
	z-index: 99999 !important;
	background: rgb(0, 0, 0, 90%);
}

div#vjs_video_3 {
	width: 100%;
}

button.vjs-big-play-button {
	top: 50% !important;
	left: 50% !important;
	font-size: 1.5em !important;
	width: 36px !important;
	height: 36px !important;
	margin-left: -22.5px;
	margin-top: -12px;
	line-height: 36px !important;
	border-radius: 50% !important;
	padding: 0 !important;
}

.icon-trash{
	background-color: var(--gray-900);
	width: 33px;
	height: 33px;
	border-radius: 100%;
	font-size: 16px;
	color: var(--white);
	line-height: 33px;
	text-align: center;
	display: block;
	top: 10px;
	right: 6px;
}
span.btn-mute-modal {
	width: 45px;
	height: 45px;
	overflow: hidden;
	position: fixed;
	top: 60px;
	right: 8px;
	z-index: 1;
	cursor: pointer;
}

span.btn-mute-modal svg {
	width: 100%;
	height: 100%;
}
.modal-slider-button-next {
	width: 48px !important;
	height: 48px !important;
	position: fixed;
	right: 8px;
}

.modal-slider-button-next:after {
	font-size: 1rem !important;
}

.modal-slider-button-prev {
	width: 48px !important;
	height: 48px !important;
	position: fixed;
	left: 8px;
}

.modal-slider-button-prev:after {
	font-size: 1rem !important;
}

.video.position-relative.event-videos > div {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	opacity: 0;
}
/*video yükleme popup*/
.video-image-boxes {
	width: 182px;
	margin: 12px 0 0 0 !important;
	height: 319px !important;
	display: block !important;
	border-radius: 8px;
}

.video-image-boxes > div {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}

.video-image-boxes .image-box {
	max-height: 100%;
	overflow: hidden;
	background-position: center !important;
	position: relative;
}

.video-image-boxes form.image-upload {
	padding: 0 !important;
	margin: 0 !important;
	height: 100% !important;
	max-height: 100% !important;
}

.video-image-boxes form.image-upload label {
	display: block;
	margin: 0;
	height: 100%;
	opacity: 0;
}
div#videoContentModal {
	z-index: 9999;
	background: #000;
}
.video-image-boxes .image-box > span[role="button"] {
	right: 12px;
	top: 12px;
}

.video-image-boxes .image-box > span[role="button"]:hover svg path {
	fill: red !important;
}

/*yeni play butonu*/
span.btn-open-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -24px;
	width: 48px;
	height: 66px;
	margin-top: -24px;
	z-index: 2;
}

span.btn-open-modal:before {
	content: "\e90c";
	font-family: 'icomoon';
	color: #fff;
	font-size: 3rem;
	/* position: absolute; */
	left: 50%;
	top: 46%;
	margin-left: 0px;
	margin-top: 0;
	cursor: pointer;
}

.video-modal .modal-dialog {
	margin: 0 !important;
	padding: 0 !important;
	border: thin solid;
	width: 100%;
	height: 100%;
	max-width: 100%;
	overflow: hidden;
}

.video-modal .modal-dialog .container-fluid {
	padding: 0;
}

.video-modal .modal-dialog .video-js {
	width: 100vw !important;
	height: 100vh !important;
}

.vjs-control-bar{
	visibility: hidden !important;
}
.winner-info .video{
	position: relative;
}
span.btn-trash{
	position: absolute;
	top: 8px;
	right: 8px;
}

.btn-playorpause{
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -24px;
	margin-left: -24px;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	border: 1px solid #fff;
}

.btn-playorpause:before {
	content: "\e90c";
	font-family: 'icomoon';
	color: #fff;
	position: absolute;
	left: 0;
	top: -13px;
	font-size: 3em;
	cursor: pointer;
}

div#uploadedVideoModal {
	z-index: 99999;
	background: #000;
}

/*custom slider*/
span.btn-modal-next {
	position: fixed;
	right: 8px;
	top: calc(50% + 28px);
	width: 45px;
	height: 45px;
	background-color: #8a8a8a;
	border-radius: 50%;
	transform: rotate(90deg);
	cursor: pointer;
}

span.btn-modal-next svg {
	width: 50%;
	height: 50%;
	margin: 0 auto;
	display: block;
	transform: translate(2px, 11px);
}

span.btn-modal-next svg path {
	fill: #0b0b0b;
}

span.btn-modal-prev {
	position: fixed;
	right: 8px;
	top: calc(50% - 28px);
	width: 45px;
	height: 45px;
	background-color: #8a8a8a;
	border-radius: 50%;
	transform: rotate(-90deg);
	cursor: pointer;
}

span.btn-modal-prev svg {
	width: 50%;
	height: 50%;
	margin: 0 auto;
	display: block;
	transform: translate(2px, 11px);
}

span.btn-modal-prev svg path {
	fill: #0b0b0b;
}

.slide-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.slide-wrapper .slide-items {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	transition: all 1s ease;
}
.slide-wrapper .slide-items > div {
	max-width: 64vh;
	margin: 0 auto;
}

.slide-wrapper .slide-items > div video {
	object-fit: cover;
	height: 100vh;
}

span.video-cover {
	top: 0;
	left: 0;
	position: fixed !important;
	width: 100%;
	height: 100%;
	z-index: 0;
	cursor: pointer;
}

.btn-playorpause.fixed{
	position: fixed;
}

@media only screen and (min-width: 600px) {
	.slide-wrapper .slide-items > div {
		max-width: 100vh;
		margin: 0 auto;
	}
	
	.slide-wrapper .slide-items > div video {
		object-fit: contain;
	}
}
</style>
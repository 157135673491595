<script>
export default {
    name: "FooterComponent",
}
</script>
<template>
    <!-- FOOTER -->
  <footer class="footer">
    <div class="container-xxl">
      <div class="row">
        <div class="col-lg-4 text-lg-start text-center">
          <div class="footer-logo text-lg-start text-center">
            <img src="../assets/img/superclick-logo-mid.svg" alt="Image">
          </div>
          <p>SuperClick <br> projesi ClickMeLive Teknoloji A.Ş. <br> tarafından tasarlanmış ve geliştirilmiştir.</p>
          <ul class="footer-socials list-inline">
            <li class="list-inline-item">
              <a href="https://instagram.com/thisissuperclick" target="_blank" class="d-block text-decoration-none">
								<i class="icon-instagram"></i>
							</a>
            </li>
            <li class="list-inline-item">
              <a href="https://youtube.com/channel/UCJECWHlSlaM1ow6PXzY9N_A" target="_blank" class="d-block text-decoration-none">
								<i class="icon-youtube"></i>
							</a>
            </li>
            <li class="list-inline-item">
              <a href="https://discord.gg/DVyUscYPwB" target="_blank" class="d-block text-decoration-none">
								<i class="fa-brands fa-discord"></i>
							</a>
            </li>
          </ul>
        </div>
        <div class="col-lg col-md-4 text-md-start text-center">
          <h4 class="fw-bold">Yasal Metinler</h4>
          <ul class="list-unstyled mb-30">
            <li><a href="#" class="d-block fw-medium text-decoration-none">Gizlilik Sözleşmesi</a></li>
            <li><a href="#" class="d-block fw-medium text-decoration-none">KVKK Aydınlatma Metni</a></li>
            <li><a href="#" class="d-block fw-medium text-decoration-none">Mesafeli Satış Sözleşmesi</a></li>
            <li><a href="#" class="d-block fw-medium text-decoration-none">Üyelik Sözleşmesi</a></li>
            <li><a href="#" class="d-block fw-medium text-decoration-none">Cayma Hakkı ve İade Koşulları</a></li>
          </ul>
        </div>
        <div class="col-lg col-md-4 text-md-start text-center">
          <h4 class="fw-bold">Hakkımızda</h4>
          <ul class="list-unstyled mb-30">
            <li><a href="#" class="d-block fw-medium text-decoration-none">Hakkımızda</a></li>
            <li><a href="#" class="d-block fw-medium text-decoration-none">Mağaza Ve Yayıncı Başvurusu</a></li>
            <li><a href="#" class="d-block fw-medium text-decoration-none">Sık Sorulan Sorular</a></li>
            <li><a href="#" class="d-block fw-medium text-decoration-none">İletişim</a></li>
            <li><a href="#" class="d-block fw-medium text-decoration-none">Kariyer</a></li>
          </ul>
        </div>
        <div class="col-lg col-md-4 text-md-start text-center">
          <h4 class="fw-bold">İletilşim</h4>
          <ul class="list-unstyled mb-0">
            <li class="fw-medium">Mecidiyeköy Yolu Cd.</li>
            <li class="fw-medium">Trump AVM</li>
            <li class="fw-medium">-No:12 K:2 İç Kapı No:214</li>
            <li class="fw-medium">Şişli / İstanbul</li>
            <li class="phone">
              <a href="#" class="d-block fw-medium text-decoration-none">
                <i class="icon-phone"></i>&nbsp;&nbsp;+90 850 737 38 38
              </a>
            </li>
            <li class="email">
              <a href="#" class="d-block fw-medium text-decoration-none">
                <i class="icon-mail"></i>&nbsp;&nbsp;info@clickmelive.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
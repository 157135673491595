<script>
/* eslint-disable */
import videojs from "video.js"
export default {
    name: "VideosComponent",
    props: {
      options: {
        type: Object,
        default() {
          return {
            aspectRatio: '16:9',
            autoplay: false,
            controls: true,
            sources: [
              {
                src: "https://clickmelive-public.s3.eu-central-1.amazonaws.com/onboard/Super-Click-101.m4v",
                type: "video/mp4",
              },
            ],
          };
        }
      }
    },
    data(){
      return{
        player: null,
        previewVideoUrl: "https://clickmelive-public.s3.eu-central-1.amazonaws.com/onboard/Super-Click-101.m4v"
      }
    },
    mounted() {
      this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {});
    },
    beforeUnmount() {
      if (this.player) {
        this.player.dispose()
      }
    }
}
</script>
<template>
    <section class="video-section">
      <div class='container-fluid container-xxl'>
        <div class='row'>
          <div class='col-lg-9 col-11 mx-auto'>
            <video @click="videoControl(e)" ref="videoPlayer" class='video-js vjs-default-skin vjs-16-9'  width='640' height='264' poster='../assets/img/homepage-thumbnail.jpg' >
              <source v-bind:src="previewVideoUrl" />
            </video>
          </div>
        </div>
      </div>
  </section>
</template>
<style scoped>
.video-js.vjs-16-9 button.vjs-big-play-button {
	width: 42px !important;
	height: 42px !important;
	line-height: 42px !important;
	padding: 0 !important;
	border-radius: 50% !important;
	top: 50% !important;
	left: 50% !important;
	margin-left: -21px;
	margin-top: -21px;
}


.video-js {
	position: relative;
}
</style>
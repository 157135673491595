<script>

export default {
    name: "UpcomingEvents",
		data(){
			return{}
		},
    props: {
      Events: Array
    },
    methods: {
      eventDetail(eventId){
        location.href = "/event-detail/"+eventId
      },
    },
	mounted() {
  }
}
</script>
<template>
	<section class="upcoming-events">
		<div class="container-xxl">
			<!--desktop-->
			<div class="row">
				<div class="col-12 section-heading">
					<h2 class="fw-medium">Açık Etkinlikler</h2>
				</div>
			</div>
			<div class="d-none d-md-flex row row-cols-3">
				<div class="col-md-12 col-11 mx-auto mx-md-0">
					<div class="swiper-upcoming-events-desktop swiper">
						<div class="swiper-wrapper">
              <template v-for="event in Events">
                <div :key="event.eventId"  v-if="event.type == 1" class="swiper-slide upcoming-event h-auto">
                  <a @click="eventDetail(event.eventId)"  href="#"><img :src="event.image" alt="Image" class="w-100"></a>
                </div>
              </template>
						</div>
						<div class="swiper-pagination position-relative"></div>
					</div>
				</div>
			</div>
			<!--mobile-->
			<div class="row d-md-none">
				<div class="col-md-12 col-11 mx-auto mx-md-0">
					<div class="swiper-upcoming-events swiper">
						<div class="swiper-wrapper">
							<template v-for="event in Events" >
								<div :key="event.eventId" v-if="event.type == 1" class="swiper-slide upcoming-event h-auto">
									<a @click="eventDetail(event.eventId)" href="#"><img :src="event.image" alt="Image" class="w-100"></a>
								</div>
							</template>
						</div>
						<div class="swiper-pagination position-relative"></div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<style scoped>
.swiper-slide.upcoming-event.h-auto img {
	max-height: 190px;
}
</style>
<script>
/* eslint-disable */
export default {
	name: "ChampionsComponent",
	methods: {
		showAllChamps(e){
			e = event;
			let hiddenColumn = document.querySelector(".champions-row > div:last-child")
			hiddenColumn.classList.toggle("hidden-column")
			let text = e.target.innerText.trim()
			if (text.toLowerCase() === "tüm listeyi gör"){
				e.target.innerText = "Tüm Listeyi Kapat"
				e.target.classList.add("rotate180")
				hiddenColumn.style.display = "block"
			}else {
				e.target.innerText = "Tüm Listeyi Gör"
				e.target.classList.remove("rotate180")
				hiddenColumn.style.display = "none"
			}
		}
	}
}
</script>
<template>
    <section class="champions">
    <div class="container-xxl">
      <div class="row">
        <div class="col-12 section-heading text-center">
          <h2 class="fw-semibold">Mart Şampiyonları</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-11 mx-lg-auto">
          <div class="champions-row row">
            <div class="col-md-6">
              <table class="champions-table table table-borderless">
                <thead>
                  <tr>
                    <th></th>
                    <th class="fw-semibold text-center">Adı/Soyadı</th>
                    <th class="fw-semibold text-center">Etkinlik</th>
                    <th class="fw-semibold text-center">Ödül</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">1</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">1</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">3</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">4</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">5</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">6</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">7</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">8</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">9</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">10</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-6 hidden-column">
              <table class="champions-table table table-borderless">
                <thead>
                  <tr>
                    <th></th>
                    <th class="fw-semibold text-center">Adı/Soyadı</th>
                    <th class="fw-semibold text-center">Etkinlik</th>
                    <th class="fw-semibold text-center">Ödül</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">11</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">12</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">13</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">14</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">15</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">16</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">17</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">18</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">19</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                  <tr>
                    <td class="align-middle position-relative">
                      <span class="counter position-absolute top-50 translate-middle-y fw-semibold text-center rounded-circle">20</span>
                      <div class="image">
                        <img src="../assets/img/champion.jpg" alt="Image" class="rounded-circle">
                      </div>
                    </td>
                    <td class="align-middle fw-semibold text-center">GİZEM KILIÇ</td>
                    <td class="align-middle fw-medium text-center">200</td>
                    <td class="award-money align-middle fw-semibold text-center">500.000₺</td>
                  </tr>
                  <tr class="empty-table-cell">
                    <td colspan="4" class="border-0 rounded-0"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-md-none">
        <div class="col-12 text-center">
          <button
						@click="showAllChamps(e)"
						type="button"
						class="btn-table btn border-0 fw-semibold rounded-0 shadow-none p-0"
						style="color: yellow;">
						Tüm Listeyi Gör
					</button>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
section.champions {
	padding: 0 8px;
	margin-bottom: 16px;
}
td span.counter {
	width: 24px !important;
	height: 24px !important;
	line-height: 24px !important;
	font-size: 0.7rem !important;
}
.rotate180:after{
	transform: rotate(180deg) !important;
}
.btn-table::after {
	display: inline-block;
	content: "\e919";
	font-family: var(--icomoon);
	font-size: 7px;
	margin-left: 8px;
	vertical-align: middle;
	transform: rotate(0);
}
</style>
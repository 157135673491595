import { createRouter, createWebHistory } from "vue-router"

/**
 * components
 */

import LandingPage from "../views/LandingPage";
import LoginPage from "../views/LoginPage";
import RegisterPage from "../views/RegisterPage"
import AboutPage from "../views/AboutPage"
import EventsPage from "../views/EventsPage"
import EventDetail from "@/views/EventDetail";
import VerifyPage from "@/views/VerifyPage"
import ProductDetail from "@/views/ProductDetail";
import ProfilPage from "@/views/ProfilPage";
import EditProfilPage from "@/views/EditProfilePage";
import ProgressPayments from "../views/ProgressPayments";
import MyEvents from "@/views/Profile/MyEvents";
import MyVideos from "@/views/Profile/MyEvents";
import MyPurchases from "@/views/Profile/MyEvents";
import TopVideosPage from "@/views/TopVideosPage";

const routes = [
    {
        path: "/",
        name: "LandingPage",
        component: LandingPage,
    },
    {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
    },
    {
        path: "/verify",
        name: "VerifyPage",
        component: VerifyPage,
    },
    {
        path: "/register",
        name: "RegisterPage",
        component: RegisterPage,
    },
    {
        path: "/about-us",
        name: "AboutPage",
        component: AboutPage,
    },
    {
        path: "/etkinlikler",
        name: "EventsPage",
        component: EventsPage,
    },
    {
        path: "/event-detail/:id",
        name: "EventDetail",
        component: EventDetail,
    },
    {
        path: "/product-detail/:id",
        name: "ProductDetail",
        component: ProductDetail,
    },
    {
        path: "/profile",
        name: "Profile",
        component: ProfilPage,
    },
    {
        path: "/edit-profile",
        name: "EditProfile",
        component: EditProfilPage
    },
    {
        path: "/etkinliklerim",
        name: "MyEvents",
        component: MyEvents,
    },
    {
        path: "/urunlerim",
        name: "MyPurchases",
        component: MyPurchases,
    },
    {
        path: "/videolarim",
        name: "MyVideos",
        component: MyVideos,
    },
    {
        path: "/progress-payments",
        name: "ProgressPayments",
        component: ProgressPayments
    },
    {
        path: "/all-top-videos",
        name: "TopVideosPAge",
        component: TopVideosPage
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
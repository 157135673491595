<script>
export default {
    name: "AboutPage"
}
</script>
<template>
    <!-- BG-GRAY-800 -->
  <section class="bg-gray-800 bg-gray-height d-flex align-items-center">
    <div class="container-xxl">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item fw-semibold active" aria-current="page">Hakkımızda</li>
        </ol>
      </nav>
    </div>
  </section>
  <!-- ABOUT -->
  <section class="about">
    <div class="container-xxl">
      <div class="clearfix">
        <div class="float-md-start about-image">
          <img src="../assets/img/about.jpg" alt="Image" class="w-100">
        </div>
        <h2 style="color:#fff">Super Click’e hoş geldin! </h2>
        <br/><p>Super Click; içerik üretmekten zevk alan, her şeyin modasını yakından takip eden, birlikten kuvvet doğduğunu düşünen ve sevdiği şeyi yaparken gelir kazananların oluşturduğu özel bir komünite.</p>
        <p> Eğer içerik üretmeyi seviyor, trendleri takip ediyor, ve kendi takipçi havuzunu geliştirirken bir yandan da gelir kazanmak istiyorsan doğru yerdesin!
            Super Click grubuna girmek için tek yapman gereken, discord kullanıyor olmak ve eventlerimiz için takipte kalmak!</p>
        <p>Event havuzumuzdan kendine uygun olanları seçebilir, en güçlü içerik üretici olup Super Click içindeki seviyeni yükseltebilirsin, aynı zamanda eventlerimizin ödüllerini kazanırsın.</p>
        <p>Super Click eventlerinin içeriklerini üretebilmek için gerekli olan ürünleri sitemizden bulabilirsin. Bu ürünleri satın almak için uygulamamız ClickMeLive’a yönlendirileceksin. </p>
        <p>Sonrası ise senin hayal gücüne kalmış!</p>
       </div>
    </div>
  </section>
	<!-- APP-DOWNLOAD -->
	<section class="app-download">
		<div class="container-xxl">
			<div class="bg-gradient-2 rounded-20 position-relative overflow-hidden">
				<div class="row">
					<div class="app-info col-md-5 offset-md-1 col-10 position-relative text-center text-md-start">
						<h2 class="fw-bold">ClickMeLive Video <br> Alışveriş</h2>
						<p>Sosyal medya fenomenleri ve birbirinden renkli sunucuların anlatımıyla ürünleri telefonundan canlı izle.
							Arkadaşlarınla, sevdiklerinle paylaş ve video alışveriş keyfini birlikte yaşa.</p>
					</div>
					<div class="app-store-col col-md-5 col-11 mx-md-0 mx-auto">
						<div class="d-flex flex-column h-100">
							<div class="app-stores d-flex flex-nowrap mt-md-auto ms-auto me-auto me-md-0">
								<a target="_blank" href="https://play.google.com/store/apps/details?id=com.clickme.clickmelive&gl=TR"><img src="../assets/img/icons/playmarket.svg" alt="Image" class="img-fluid"></a>
								<a target="_blank" href="https://apps.apple.com/uz/app/clickmelive/id1571757578"><img src="../assets/img/icons/app-store.svg" alt="Image" class="img-fluid"></a>
							</div>
						</div>
					</div>
				</div>
				<div class="circle-left position-absolute top-50 translate-middle-y">
				</div>
				<div class="circle-right position-absolute">
				</div>
				<div class="circle-center position-absolute"></div>
			</div>
		</div>
	</section>
</template>